import Axios from './apiService';
export const GetTalent = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'user/talent',
      params: {
        id,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getOrdersForBuyers = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'order/user',
      params: {
        id,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getOrdersForTalents = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'order/talent',
      params: {
        id,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllOrders = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'order/all',
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateOrderStatusToDelivered = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'order/delivered',
      params: {
        id,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateOrderStatusToReceived = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'order/received',
      params: {
        id,
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const createOrder = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `order`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};
