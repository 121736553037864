import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import { Input, Button, Row, Col, Checkbox, Radio, Space, notification, Spin } from "antd";
import validationSchema from "./validation";
import CustomButton from "app/common/custom-button/CustomButton";
import styles from "./Personal.module.scss";
import formSchema from "./validation";
import { submitApplication } from "app/auth/services/Dashboard";
const { TextArea } = Input;

interface IProps {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
}

interface FormValues {
  publicName: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  location: string;
  biography: string;
  contentTypes: string[];
  socialPlatforms: string[];
  referralSource: string;
  platforms: string;
  comments: string;
  Scouting: string;
  plan: string;
  distributed: string;
  benefits: string;
  physical: string;
  token: string;
}

const contentTypes = [
  {
    label: "Business Professional / Consultant",
    value: "business",
  },
  {
    label: "Crypto / NFT / Web3",
    value: "crypto",
  },
  {
    label: "Trading",
    value: "trading",
  },
  {
    label: "Digital (Blog/Instagram/TikTok/Youtube etc...)",
    value: "digital",
  },
  {
    label: "Gaming",
    value: "gaming",
  },
  {
    label: "Music",
    value: "music",
  },
  {
    label: "Sports",
    value: "sports",
  },
  {
    label: "Art",
    value: "art",
  },
  {
    label: "Film / TV",
    value: "film",
  },
  {
    label: "Other",
    value: "other",
  },
];

const socialPlatforms = [
  {
    label: "Discord",
    value: "discord",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "Soundcloud",
    value: "soundcloud",
  },
  {
    label: "Telegram",
    value: "Telegram",
  },
  {
    label: "Twitch",
    value: "Twitch",
  },
  {
    label: "Youtube",
    value: "Youtube",
  },
  {
    label: "Patreon",
    value: "Patreon",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "LinkedIn",
    value: "LinkedIn",
  },
  {
    label: "Personal Website",
    value: "Personal",
  },
  {
    label: "Spotify",
    value: "Spotify",
  },
  {
    label: "TikTok",
    value: "TikTok",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const referralSources = [
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "Discord",
    value: "discord",
  },
  {
    label: "Telegram",
    value: "Telegram",
  },
  {
    label: "TalentChain Team",
    value: "TalentChain",
  },
  {
    label: "Talented Creator/Maker",
    value: "Talented",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const initialValues: FormValues = {
  publicName: "",
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  location: "",
  biography: "",
  contentTypes: [],
  socialPlatforms: [],
  platforms: "",
  referralSource: "",
  comments: "",
  Scouting: "",
  plan: "",
  distributed: "",
  benefits: "",
  physical: "",
  token: "",
};

const font = { fontSize: "18px", fontWeight: "bold" };
const inputStyle = {
  marginBottom: "10px",
  backgroundColor: "#3f4f7e",
  color: "#fff",
  borderRadius: "20px",
};
const errorMessage = { color: "red" };
const inputDivStyle = { margin: "20px 0 0px", height: "90px" };
const submit = {
  borderRadius: "32px",
  fontSize: "14px",
  padding: "4px 16px",
  height: "32px",
  backgroundColor: "#192042",
  color: "#ffff",
  border: "1px solid #c3e0fd",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#192042",
  },
};

const Personal: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getProduct(values) {
    setLoading(true)
    const response = await submitApplication(values);
    if (response.status === 200 || response.status === 201) {
      notification.success({
        message: "Operation Successful",
        description: "Your Application for Tokenization has been submitted",
      });
    } else {
      notification.error({
        message: "Operation Failed",
        description: "Please try again",
      });
    }
    setLoading(false)
    resetForm();
    window.scrollTo(0, 0);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      getProduct(values);
    },
    validateOnChange: true,
  });

  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    resetForm,
    touched,
  } = formik;

  console.log(errors, "values errors");

  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 4,
          display: loading ? "block" : "none",
        }}
        className={styles["flex-box"]}
      >
        <Spin size="large" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles["nameField"]}>
          <div className={styles["Fields"]}>
            <label style={font} htmlFor="publicName">
              Public Name
            </label>
            <Input
              id="publicName"
              name="publicName"
              type="text"
              onChange={handleChange}
              value={values.publicName}
              onBlur={formik.handleBlur}
              style={inputStyle}
            />
            {touched.publicName && errors.publicName ? (
              <span style={errorMessage}> {errors.publicName}</span>
            ) : (
              ""
            )}
          </div>

          <div className={styles["Fields"]}>
            <label style={font} htmlFor="firstName">
              First Name
            </label>
            <Input
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={formik.handleBlur}
              style={inputStyle}
            />
            {touched.firstName && errors.firstName ? (
              <span style={errorMessage}> {errors.firstName}</span>
            ) : (
              ""
            )}
          </div>

          <div className={styles["Fields"]}>
            <label style={font} htmlFor="lastName">
              Last Name
            </label>
            <Input
              id="lastName"
              name="lastName"
              type="text"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              value={values.lastName}
              style={inputStyle}
            />
            {touched.lastName && errors.lastName ? (
              <span style={errorMessage}> {errors.lastName}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={styles["emailLocation"]}>
          <div className={styles["email"]}>
            <div style={inputDivStyle}>
              <label style={font} htmlFor="email">
                Email
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={values.email}
                style={inputStyle}
              />
              {touched.email && errors.email ? (
                <span style={errorMessage}> {errors.email}</span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles["Location"]}>
            <div style={inputDivStyle}>
              <label style={font} htmlFor="location">
                Location
              </label>
              <Input
                name="location"
                type="text"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={values.location}
                style={inputStyle}
              />
              {touched.location && errors.location ? (
                <span style={errorMessage}> {errors.location}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div style={inputDivStyle}>
          <label style={font} htmlFor="address">
            Address
          </label>
          <TextArea
            id="address"
            name="address"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.address}
            style={inputStyle}
          />

          {touched.address && errors.address ? (
            <span style={errorMessage}> {errors.address}</span>
          ) : (
            ""
          )}
        </div>

        <div style={inputDivStyle}>
          <label style={font} htmlFor="biography">
            Biography
          </label>
          <TextArea
            id="biography"
            name="biography"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.biography}
            style={inputStyle}
          />
          {touched.biography && errors.biography ? (
            <span style={errorMessage}> {errors.biography}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font}>
            Please select the content type that is most applicable to you:
          </label>
          {contentTypes.map((contentType) => (
            <div key={contentType.value}>
              <label>
                <Checkbox
                  name="contentTypes"
                  style={{ marginRight: "10px" }}
                  value={contentType.value}
                  checked={values.contentTypes.includes(contentType.value)}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    const value = event.target.value;

                    formik.setFieldValue(
                      "contentTypes",
                      isChecked
                        ? [...values.contentTypes, value]
                        : values.contentTypes.filter((val) => val !== value)
                    );
                  }}
                />
                {contentType.label}
              </label>
            </div>
          ))}
          {touched.contentTypes && errors.contentTypes ? (
            <span style={errorMessage}> {errors.contentTypes}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font}>
            What are your main social platforms? (Select all that apply)
          </label>
          {socialPlatforms.map((platform) => (
            <div key={platform.value}>
              <label>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  name="socialPlatforms"
                  value={platform.value}
                  checked={values.socialPlatforms.includes(platform.value)}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    const value = event.target.value;

                    formik.setFieldValue(
                      "socialPlatforms",
                      isChecked
                        ? [...values.socialPlatforms, value]
                        : values.socialPlatforms.filter((val) => val !== value)
                    );
                  }}
                />
                {platform.label}
              </label>
            </div>
          ))}
          {touched.socialPlatforms && errors.socialPlatforms ? (
            <span style={errorMessage}> {errors.socialPlatforms}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font} htmlFor="platforms">
            Please paste the links of your main social platforms (where your
            community is), and indicate the number of followers per platform.
          </label>
          <Input
            id="platforms"
            name="platforms"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.platforms}
            style={inputStyle}
          />
          {touched.platforms && errors.platforms ? (
            <span style={errorMessage}> {errors.platforms}</span>
          ) : (
            ""
          )}
        </div>
        <br />
        <p>
          Next steps - Tell us more about your personal token! A personal or
          social token is a tool that can be used to authenticate access or to
          facilitate transactions. We’d love to hear some details around what
          you have planned for your coin-holders. Be as creative and complete as
          you can here, but don’t worry if you don't know yet or if you’re still
          trying to figure out the details.
        </p>
        <div style={{ marginTop: "20px" }}>
          <label style={font} htmlFor="token">
            Tell us why a token is right for you and your community, and how you
            think it would be successful. Use this space to not only make the
            case for your token, but also why you think you are the right person
            to launch this coin.
          </label>
          <Input
            id="token"
            name="token"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.token}
            style={inputStyle}
          />
          {touched.token && errors.token ? (
            <span style={errorMessage}> {errors.token}</span>
          ) : (
            ""
          )}
        </div>
        <p style={{ marginTop: "30px", fontSize: "22px", fontWeight: "bold" }}>
          What will your audience be able to purchase with your token?
        </p>
        <div style={{ marginTop: "20px" }}>
          <p>
            These are physical/digital items, services or events that your
            community is able to purchase using your token. Examples: Access to
            virtual meetings with you, merchandise, exclusive events, access to
            private channels, digital products, NFTs etc...
          </p>
          <Input
            id="physical"
            name="physical"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.physical}
            style={inputStyle}
          />
          {touched.physical && errors.physical ? (
            <span style={errorMessage}> {errors.physical}</span>
          ) : (
            ""
          )}
        </div>
        <p style={{ marginTop: "30px", fontSize: "22px", fontWeight: "bold" }}>
          What perks/access will your audience receive for holding your token?
        </p>
        <div style={{ marginTop: "20px" }}>
          <p>
            These are benefits that are unlocked after becoming an owner of your
            token. Think of these as perks that are activated for being a member
            of your fan club. Examples: Exclusive content, ticket or NFTs
            presales, access to newsletters, limited edition items, memberships.
          </p>
          <Input
            id="benefits"
            name="benefits"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.benefits}
            style={inputStyle}
          />
          {touched.benefits && errors.benefits ? (
            <span style={errorMessage}> {errors.benefits}</span>
          ) : (
            ""
          )}
        </div>

        <p style={{ marginTop: "30px", fontSize: "22px", fontWeight: "bold" }}>
          How will you drive adoption of your token?
        </p>

        <div style={{ marginTop: "20px" }}>
          <p>
            Coins can be distributed freely to anyone with a TalentChain account
            via our Airdrop Tool. We recommend gifting coins to supporters as
            part of launch and having ongoing ways for your fans to earn more.
            Earning coins should be aligned with any behaviors you’d like to
            incentivize. Examples: Coin airdrops, subscriber rewards, newsletter
            sign-ups, user-generated content submissions, music pre-saves,
            giveaways.
          </p>
          <Input
            id="distributed"
            name="distributed"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.distributed}
            style={inputStyle}
          />
          {touched.distributed && errors.distributed ? (
            <span style={errorMessage}> {errors.distributed}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font} htmlFor="plan">
            Do you plan to list any products or services in the platform? If yes
            what kind of products and services?
          </label>
          <Input
            id="plan"
            name="plan"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.plan}
            style={inputStyle}
          />
          {touched.plan && errors.plan ? (
            <span style={errorMessage}> {errors.plan}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font}>How did you hear about TalentChain?</label>
          {referralSources.map((source) => (
            <div key={source.value}>
              <label>
                <Radio
                  name="referralSource"
                  value={source.value}
                  checked={values.referralSource === source.value}
                  onChange={handleChange}
                />
                {source.label}
              </label>
            </div>
          ))}
          {touched.referralSource && errors.referralSource ? (
            <span style={errorMessage}> {errors.referralSource}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font} htmlFor="Scouting">
            Talent Scouting Referral Program - If someone recommended you to be
            listed on our platform, please indicate the referrer name and social
            information to reach out to him.
          </label>
          <Input
            id="plan"
            name="Scouting"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.Scouting}
            style={inputStyle}
          />
          {errors && errors ? (
            <span style={errorMessage}> {errors.Scouting}</span>
          ) : (
            ""
          )}
        </div>

        <div style={{ marginTop: "20px" }}>
          <label style={font} htmlFor="comments">
            If you have any comments or suggestions, please feel free.
          </label>
          <Input
            id="comments"
            name="comments"
            type="text"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={values.comments}
            style={inputStyle}
          />
          {errors && errors ? (
            <span style={errorMessage}> {errors.comments}</span>
          ) : (
            ""
          )}
        </div>
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CustomButton className="primary-btn" htmlType="submit" disabled={loading}>
            Submit
          </CustomButton>
          {/* <CustomButton onClick={() => formik.resetForm()}>Clear</CustomButton> */}
        </Space>
      </form>
    </div>
  );
};

export default Personal;
