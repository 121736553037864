import * as React from "react";
import { Select } from "antd";
import "./CustomSelect.module.scss";
import { DefaultOptionType } from "antd/lib/select";
interface CustomSelectProps {
  className?: string;
  placeholder?: string;
  options: { title: string; value: string | number }[];
  defaultValue?: string | number;
  value?: string | number;
  fieldNames?: string | number;
  disabled?: boolean;
  rows?: number;
  onChange?: (
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ) => void | undefined;
}

const CustomSelect: React.FunctionComponent<CustomSelectProps> = (props) => {
  const { Option } = Select;
  return (
    <>
      <Select
        disabled={props?.disabled || false}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        className={props.className}
        value={props.value}
      >
        {props.options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.title}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CustomSelect;
