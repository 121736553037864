import Axios from './apiService';

export const Upload = async ({ type, formData }) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'user/upload/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        type,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const uploadTalentProfileOrCover = async ({ id, type, formData }) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'admin/upload-talent',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        type,
        id
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const uploadKYCDocument = async ({ id, formData }) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'user/kyc',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        id
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const createService = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `service`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const createGood = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `good`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const addTalent = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/talent`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const UploadService = async (id, formData) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'service/upload/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        service_id: id,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const UploadGood = async (id, formData) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'good/upload/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        id: id,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const updateService = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `service/update?service_id=${id}`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const removeService = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `service/remove?service_id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const removeGood = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `good/remove?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateGood = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `good/update?id=${id}`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getService = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `service?service_id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getGood = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `good?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getServices = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'service/all',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getServicesForTalent = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `service/talent?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getProductsForTalent = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `good/talent?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getGoods = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'good/all',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getCurrencyPricesJSON = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'currency/prices',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getGoodsCategories = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'category/product',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getServiceCategories = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'category/service',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getServicesByCategory = async (category, page, id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `service/category?category=${category}&page=${page}&id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getGoodsByCategory = async (category, page, id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `good/category?category=${category}&page=${page}&id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const BioUpdate = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: 'user/update',
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resendConfirmationEmail = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'user/resend-confirmation'
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateTalent = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/update-talent?id=${id}`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetAllTalents = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'user/talents',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetTalentsBySkill = async (skill, page) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `user/talents-by-skills?skill=${skill}&page=${page}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetTalentsSkill = async (skill, page) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `user/talents-skill`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetFeaturedTalents = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'user/featured-talents',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetTalent = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `user/talent?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const GetUser = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `user`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const findProduct = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `good?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const findService = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `service?service_id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const submitApplication = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `tokenize`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};
export const getApplications = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `tokenize`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};