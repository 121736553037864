import Axios from './apiService';

export const getAllMessages = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `chat`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const postMessage = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `chat`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateProfileEmail = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `user/update/email`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const Upload = async (recipient, formData) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'chat/upload/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        recipient,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};
