export const ServicesSvg = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.97 5.26975C18.0627 5.17724 18.1362 5.06735 18.1864 4.94637C18.2366 4.8254 18.2624 4.69572 18.2624 4.56475C18.2624 4.43378 18.2366 4.3041 18.1864 4.18312C18.1362 4.06215 18.0627 3.95226 17.97 3.85975L15.14 1.02975C15.0475 0.937045 14.9376 0.863498 14.8166 0.813316C14.6957 0.763135 14.566 0.737305 14.435 0.737305C14.304 0.737305 14.1743 0.763135 14.0534 0.813316C13.9324 0.863498 13.8225 0.937045 13.73 1.02975L9.24 5.51975L5.35 1.62975C4.57 0.849749 3.3 0.849749 2.52 1.62975L0.62 3.52975C-0.16 4.30975 -0.16 5.57975 0.62 6.35975L4.51 10.2497L0 14.7597V18.9997H4.24L8.76 14.4797L12.65 18.3697C13.6 19.3197 14.88 18.9697 15.48 18.3697L17.38 16.4697C18.16 15.6897 18.16 14.4197 17.38 13.6397L13.49 9.74975L17.97 5.26975ZM2.04 4.93975L3.93 3.03975L5.2 4.30975L4.02 5.49975L5.43 6.90975L6.62 5.71975L7.82 6.91975L5.92 8.81975L2.04 4.93975ZM13.27 12.3797L12.08 13.5697L13.49 14.9797L14.68 13.7897L15.95 15.0597L14.05 16.9597L10.16 13.0697L12.06 11.1697L13.27 12.3797ZM3.41 16.9997H2V15.5897L11.61 5.97975L12.91 7.27975L13.02 7.38975L3.41 16.9997ZM13.02 4.55975L14.43 3.14975L15.84 4.55975L14.43 5.96975L13.02 4.55975Z"
        fill="black"
      />
    </svg>
  );
};
