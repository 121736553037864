import * as React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import Sidebar from "../sidebar/SideBar";
import "./DashboardLayout.module.scss";
import DashboardHeader from "../header/DashboardHeader";
import DashboardFooter from "../footer/DashboardFooter";
interface DashboardLayoutProps {
  children: React.ReactNode;
  title: string;
}

const DashboardLayout: React.FunctionComponent<DashboardLayoutProps> = (
  props
) => {
  const { Content } = Layout;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <Helmet>
      <title>TalentChain - Talent Dashboard</title>
      <meta content="TalentChain - Talent Dashboard" property="og:title"/>
      </Helmet>
      <Sidebar />
      <Layout style={{ overflow: "hidden" }}>
        <DashboardHeader title={props.title} />
        <Content className="dashboard-content">{props.children}</Content>
        <DashboardFooter />
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
