import * as React from "react";
import styles from "./NoData.module.scss";
import data from "assets/images/TalentChain - XRPL - Page not found.png";
import CustomButton from "app/common/custom-button/CustomButton";
interface NoDataProps {
  title: string;
  btnTitle: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const NoData: React.FunctionComponent<NoDataProps> = (props) => {
  return (
    <div className={styles["no-data"]}>
      <div className="text-center">
        <div className={styles["data-img"]}>
          <img src={data} alt="data" />
        </div>
        <h2 className={styles["title"]}>{props.title}</h2>
      </div>
    </div>
  );
};

export default NoData;
