import { getApplications } from "app/auth/services/Dashboard";
import React from "react";
import "./TokenizationApplication.scss";
import DashboardLayout from "../../dashboard-layout/DashboardLayout";
import { Col, Collapse, notification, Row, Spin } from "antd";
import { useParams } from "react-router";
import dayjs from "dayjs";
const { Panel } = Collapse;

interface KycRequestsProps {}

interface FormValues {
  publicName: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  location: string;
  biography: string;
  contentTypes: string[];
  socialPlatforms: string[];
  referralSource: string;
  platforms: string;
  comments?: string;
  Scouting?: string;
  plan: string;
  distributed: string;
  benefits: string;
  physical: string;
  token: string;
  updatedAt: string;
}

const TokenizationApplication: React.FunctionComponent<
  KycRequestsProps
> = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState<FormValues[]>([]);

  async function getProduct() {
    setLoading(true);

    const response = await getApplications();
    if (response.status === 200) {
      setProduct(response.data);
    } else {
      notification.error({
        message: "Applications Not Found",
        description: "There are no applications submitted .",
      });
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getProduct();
  }, []);

  const onChange = (key: string | string[]) => {};

  return (
    <DashboardLayout title="Tokenization Applications.">
      {/* <h1 style={{ fontSize: "2rem" }}>Applications</h1> */}
      <div className="container">
        <Row>
          <Col sm={24}>
            {product.length > 0 ? (
              product.map((item, i) => (
                <Collapse
                  onChange={onChange}
                  key={i}
                  style={{ marginBottom: "10px" }}
                  expandIcon={({ isActive }) => (
                    <div>{isActive ? <div></div> : <div></div>}</div>
                  )}
                >
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <h1>{`${item?.publicName}`}</h1>
                        <h1>{`${dayjs(item?.updatedAt).format(
                          "MMM D YYYY HH:mm A"
                        )}`}</h1>
                      </div>
                    }
                    key="1"
                  >
                    <div style={{ padding: "26px", background: "#f9f9f9" }}>
                      <div
                        style={{
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>First Name </h1>
                            <p> {` ${item?.firstName}`}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>Last Name </h1>
                            <p> {` ${item?.lastName}`}</p>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>Location </h1>
                            <p> {` ${item?.location}`}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>Adress </h1>
                            <p> {` ${item?.address}`}</p>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>Email Address </h1>
                            <p> {` ${item?.email}`}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              gap: 15,
                            }}
                          >
                            <h1>Token </h1>
                            <p> {` ${item?.token}`}</p>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>Biography </h1>
                        <p> {` ${item?.biography}`}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          What will your audience be able to purchase with your
                          token?
                        </h1>
                        <p> {` ${item?.physical}`}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          What perks/access will your audience receive for
                          holding your token?
                        </h1>
                        <p> {` ${item?.benefits}`}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          Please paste the links of your main social platforms
                          (where your community is), and indicate the number of
                          followers per platform
                        </h1>
                        <p> {` ${item?.platforms}`}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>How will you drive adoption of your token? </h1>
                        <p> {` ${item?.distributed}`}</p>
                      </div>
                      {/* <div style={{ display: "flex", flexDirection: "column" }}>
                    <h1>physical : </h1>
                    <p> {` ${item?.physical}`}</p>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          Do you plan to list any products or services in the
                          platform? If yes what kind of products and services?
                        </h1>
                        <p> {` ${item?.plan}`}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>How did you hear about TalentChain? </h1>
                        <p> {` ${item?.referralSource}`}</p>
                      </div>
                      {item.Scouting && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "26px",
                            background: "#fff",
                            borderRadius: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <h1>
                            Talent Scouting Referral Program - If someone
                            recommended you to be listed on our platform, please
                            indicate the referrer name and social information to
                            reach out to him
                          </h1>
                          <p> {` ${item?.Scouting}`}</p>
                        </div>
                      )}
                      {item.comments && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "26px",
                            background: "#fff",
                            borderRadius: "20px",
                            marginTop: "10px",
                          }}
                        >
                          <h1>
                            If you have any comments or suggestions, please feel
                            free
                          </h1>
                          <p> {` ${item?.comments}`}</p>
                        </div>
                      )}
                      <div
                        style={{
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          Please select the content type that is most applicable
                          to you
                        </h1>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {item.contentTypes.map((type, i) => (
                            <p style={{ marginLeft: "20px" }} key={i}>
                              {type}{" "}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div
                        style={{
                          padding: "26px",
                          background: "#fff",
                          borderRadius: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <h1>
                          What are your main social platforms? (Select all that
                          apply)
                        </h1>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {item.socialPlatforms.map((type, i) => (
                            <p style={{ marginLeft: "20px" }} key={i}>
                              {type}{" "}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              ))
            ) : (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "100px",
                  // transform: "translate(-50%, -50%)",
                  zIndex: 4,
                  // display: product.length > 0 ? "block" : "none",
                }}
                // className={styles["flex-box"]}
              >
                <Spin size="large" />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default TokenizationApplication;
