import * as React from "react";
import { Card } from "antd";
import "./CustomCard.module.scss";
interface CustomCardProps {
  bordered?: boolean;
  loading?: boolean;
  hoverable?: boolean;
  children: React.ReactNode;
  className: string;
  cover?: React.ReactNode;
  id?: string;
  extra?: React.ReactNode;
  title?: string;
  style?: Object
}

const CustomCard: React.FunctionComponent<CustomCardProps> = (props) => {
  return (
    <Card
      className={props.className}
      bordered={props.bordered}
      hoverable={props.hoverable}
      loading={props.loading}
      cover={props.cover}
      id={props.id}
      extra={props.extra}
      style={props.style}
    >
      {props.children}
    </Card>
  );
};

export default CustomCard;
