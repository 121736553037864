import React from "react";
import { Card } from "antd";
import Slider from "react-slick";

const data = [
  {
    title: "https://www.talentchain.io/service/63dcedf489e304be581c387a",
  },
  {
    title: "https://www.talentchain.io/service/63dceba289e304be581c3844",
  },
  {
    title: "https://www.talentchain.io/service/63dce64589e304be581c3819",
  },
  {
    title: "https://www.talentchain.io/service/636e5795bd4b745736848d2f",
  },
  {
    title: "https://www.talentchain.io/service/636602397e8fe07da9761f7d",
  },
  {
    title: "https://www.talentchain.io/service/6366019f7e8fe07da9761f71",
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  className: "featured-slider",
  arrows: true,
  autoplay: true,
};

const PersonalBaner = () => {
  return (
    <div>
      <div className="container">
        <Slider {...settings}>
          {data.map(({ title }) => (
            <>
              {/* <Card
                style={{
                  position: "relative",
                  height: "300px",
                  color: "#fff",
                  //   backgroundColor: "#ea1414",
                }}
              >
                <div style={{ height: "300px", overflow: "auto" }}></div>
                <style>
                  {`
          .ant-card {
            background-color: #192042;
            color:#fff;
            // width:100%
            
          }
        `}
                </style>
              </Card> */}
              <div
                style={{ background: "red", width: "100%", height: "300px" }}
              >
                <img
                  src="https://thumbs.dreamstime.com/b/consultant-presenting-tag-cloud-information-technology-227624274.jpg"
                  alt="banner"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PersonalBaner;
