export const OrderSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0625 14.4375H8.9375C8.39049 14.4375 7.86589 14.6548 7.47909 15.0416C7.0923 15.4284 6.875 15.953 6.875 16.5V17.875H8.25V16.5C8.25 16.3177 8.32243 16.1428 8.45136 16.0139C8.5803 15.8849 8.75516 15.8125 8.9375 15.8125H13.0625C13.2448 15.8125 13.4197 15.8849 13.5486 16.0139C13.6776 16.1428 13.75 16.3177 13.75 16.5V17.875H15.125V16.5C15.125 15.953 14.9077 15.4284 14.5209 15.0416C14.1341 14.6548 13.6095 14.4375 13.0625 14.4375ZM11 13.75C11.5439 13.75 12.0756 13.5887 12.5278 13.2865C12.9801 12.9844 13.3325 12.5549 13.5407 12.0524C13.7488 11.5499 13.8033 10.997 13.6972 10.4635C13.5911 9.93006 13.3291 9.44005 12.9445 9.05546C12.5599 8.67086 12.0699 8.40895 11.5365 8.30284C11.0031 8.19673 10.4501 8.25119 9.94762 8.45933C9.44512 8.66747 9.01563 9.01995 8.71346 9.47218C8.41128 9.92442 8.25 10.4561 8.25 11C8.25 11.7293 8.53973 12.4288 9.05546 12.9445C9.57118 13.4603 10.2707 13.75 11 13.75ZM11 9.625C11.2719 9.625 11.5378 9.70564 11.7639 9.85673C11.99 10.0078 12.1663 10.2226 12.2703 10.4738C12.3744 10.7251 12.4016 11.0015 12.3486 11.2683C12.2955 11.535 12.1646 11.78 11.9723 11.9723C11.78 12.1646 11.535 12.2955 11.2682 12.3486C11.0015 12.4016 10.7251 12.3744 10.4738 12.2703C10.2226 12.1663 10.0078 11.99 9.85673 11.7639C9.70564 11.5378 9.625 11.272 9.625 11C9.625 10.6353 9.76987 10.2856 10.0277 10.0277C10.2856 9.76987 10.6353 9.625 11 9.625Z"
        fill="black"
      />
      <path
        d="M17.1875 3.4375H15.125V2.75C15.125 2.38533 14.9801 2.03559 14.7223 1.77773C14.4644 1.51987 14.1147 1.375 13.75 1.375H8.25C7.88533 1.375 7.53559 1.51987 7.27773 1.77773C7.01987 2.03559 6.875 2.38533 6.875 2.75V3.4375H4.8125C4.44783 3.4375 4.09809 3.58237 3.84023 3.84023C3.58237 4.09809 3.4375 4.44783 3.4375 4.8125V19.25C3.4375 19.6147 3.58237 19.9644 3.84023 20.2223C4.09809 20.4801 4.44783 20.625 4.8125 20.625H17.1875C17.5522 20.625 17.9019 20.4801 18.1598 20.2223C18.4176 19.9644 18.5625 19.6147 18.5625 19.25V4.8125C18.5625 4.44783 18.4176 4.09809 18.1598 3.84023C17.9019 3.58237 17.5522 3.4375 17.1875 3.4375V3.4375ZM8.25 2.75H13.75V5.5H8.25V2.75ZM17.1875 19.25H4.8125V4.8125H6.875V6.875H15.125V4.8125H17.1875V19.25Z"
        fill="black"
      />
    </svg>
  );
};
