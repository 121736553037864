import React from "react";

import styles from "../about-us/AboutUs.module.scss";
import styless from "../contacts/ContactsList.module.scss";

const MaintenancePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#070d26 !important",
        // margin:"10rem 0px"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          backgroundColor: "transparent",
          borderRadius: 4,
          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          padding:"20px"
        }}
      >
        <img
          src="https://cdn.pixabay.com/photo/2012/04/16/11/39/plumber-35611__340.png"
          alt="Maintenance"
          width={280}
          height={280}
        />
        <h1 className={styles["faq-title"]}>Maintenance in Progress</h1>
        <p
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "32px",
            color: "#ffffff",
            textAlign:"center"
            // marginottom: 1.5rem;
          }}
        >
          We apologize for the inconvenience. The website is currently
          undergoing maintenance.<br/> Please check back later.
        </p>
        {/* <CircularProgress color="primary" /> */}
      </div>
    </div>
  );
};

export default MaintenancePage;
