import "app/components/dashboard/sidebar/SideBar.module.scss";
export const DownloadSVG = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8327 5.5H8.49935V0.5H3.49935V5.5H0.166016L5.99935 11.3333L11.8327 5.5ZM0.166016 13V14.6667H11.8327V13H0.166016Z"
        fill="#5542F6"
      />
    </svg>
  );
};
