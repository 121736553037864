import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: {},
  jwt: "",
  isLoggedIn: false,
  xrp: 0,
  talent: 0,
  talentPrice: 0,
  currencyPairs: [{ title: "TALENT/XRP", value: "TALENT" }],
  currencyMap: {},
  notifications: 0,
  notificationArray: [{ user: '', count: 0}],
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    userProfile: (state, { payload }) => {
      if (payload) {
        state.isLoggedIn = true;
        state.userProfile = payload.user;
        state.jwt = payload.jwt;
        state.xrp = 0;
        state.talent = 0;
        if (!!payload.user.notifications && payload.user.notifications.length > 0) {
          let count = 0;
          const notsArray: { user: string; count: number }[] = [];
          payload.user.notifications.forEach(notification => {
            count += notification.count || 0;
            const index = notsArray.findIndex( not => not.user === notification.user);
            if (index >= 0) {
              notsArray[index].count = notsArray[index].count + 1;
            } else {
              notsArray.push({user: notification.user, count: 1})
            }
          })
          state.notificationArray = notsArray;
          state.notifications = count;
        }
      }
    },
    profileUpdate: (state, { payload }) => {
      if (!!payload) {
        state.userProfile = payload;
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userProfile = {};
      state.jwt = "";
      state.xrp = 0;
      state.talent = 0;
      state.notificationArray = [];
      state.notifications = 0;
    },
    setBalances: (state, { payload }) => {
      if (payload) {
        if (payload.length >= 1) {
          state.xrp = payload[0].value;
        }
        for (let i = 1; i < payload.length; i++) {
          if (payload[i].currency === process.env.REACT_APP_CURRENCY_CODE) {
            state.talent = payload[i].value;
            break;
          }
        }
      }
    },
    setTalentPrice: (state, { payload }) => {
      state.talentPrice = payload;
    },
    setCurrencyMap: (state, {payload}) => {
      state.currencyMap = payload;
    },
    setCurrencyPairs: (state, { payload }) => {
      state.currencyPairs = payload;
    },
    setNotificationsCount: (state, {payload}) => {
      state.notifications = payload;    
    },
    addNotification: (state, {payload}) => {
      const index = state.notificationArray.findIndex(not => not.user === payload);
      if (index >= 0) {
        state.notificationArray[index].count = state.notificationArray[index].count + 1;
      } else {
        state.notificationArray.push({ user: payload, count: 1 });
      }
    },
    clearAllNotifications: (state, {payload}) => {
      state.notificationArray = [];
      state.notifications = 0;
    },
    removeNotification: (state, {payload}) => {
      const remaining = state.notificationArray.filter( 
        (notification: { user: string; }) => 
          notification.user !== payload);

      if (remaining.length === 0) {
        state.notificationArray = [];
        state.notifications = 0;
      } else {
        state.notificationArray = remaining;
      }
    },
  },
});

export const {
  userProfile,
  profileUpdate,
  logout,
  setBalances,
  setTalentPrice,
  setCurrencyMap,
  setCurrencyPairs,
  setNotificationsCount,
  addNotification,
  clearAllNotifications,
  removeNotification,
} = authSlice.actions;
export default authSlice.reducer;
