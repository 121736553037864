import React, { useEffect } from "react";
import { Space, Button, Typography } from "antd";
import CustomButton from "app/common/custom-button/CustomButton";
const { Title, Text } = Typography;
interface IProps {
  setScreen: React.Dispatch<React.SetStateAction<number>>;
}

const Terms: React.FC<IProps> = ({ setScreen }) => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <Space style={{display:'flex',flexDirection:'column'}}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          // width: "70%",
          margin: "0 auto",
        }}
      >
        <Title style={{ color: "#fff" }}>
          You have a talent? Register your interest to be part of our ecosystem.
        </Title>
        <p>
          Thank you for your interest in launching your own digital economy with
          Talentchain!
        </p>
        <Text style={{ color: "#fff" }}>
          TalentChain is a platform for self-monetization built on top of the
          XRP Ledger and aims to provide access to personal tokens all across
          the globe. It enables “Talents” - creators or makers, to launch their
          own tokens in order for them to build their own digital economy
          through an intuitive marketplace and a DEX.
        </Text>
        <Text style={{ color: "#fff" }}>
          In this way, talented creators or makers like artists, streamers,
          gamers, NFT designers, crypto and web3 experts can build their
          communities and interact with them, by allowing community members to
          earn and collect benefits or perks, as well as purchase services or
          goods.
        </Text>
        <Text style={{ color: "#fff" }}>
          To join our platform as a Talent and to be considered, please fill out
          the following application with as much accuracy and detail as
          possible. The listing is currently 100% free. Note that only completed
          applications submitted in English will be considered.
        </Text>
        <Text>
          Curious about what a successful application includes? We're looking
          for:
        </Text>
        <li>a clear biography of yourself and/or your brand</li>
        <li>links to your most popular social media profile(s) (minimum 3)</li>
        <li>
          a concise, persuasive overview of why you want to create a token
          economy with TalentChain, and how it will benefit your community
        </li>
        <li>well-thought</li>
        <li>
          out use cases for holding, earning, and spending your tokens if
          applicable
        </li>
        <Text style={{ color: "#fff" }}>Criteria for approval:</Text>
        <li>evidence of a significant talent</li>
        <li>evidence of a significant community size (not mandatory)</li>
        <li>
          evidence that you have strongly considered the role that a personal
          token economy would play in your ongoing projects
        </li>
        <Text style={{ color: "#fff" }}>
          The information you provide will not be shared publicly, and will be
          analysed internally by the TalentChain team for review.
        </Text>
        <Text style={{ color: "#fff" }}>
          The more ideas and information you share, the more likely your
          application is to be approved.
        </Text>
        <Text style={{ color: "#fff" }}>
          Do not hesitate to share this form to talented people around you.
          Questions? Please contact us via social media or
          contact@talentchain.io
        </Text>
      </div>
      <CustomButton className="primary-btn" onClick={() => setScreen(1)}>
        {" "}
        Next Step
      </CustomButton>
    </Space>
  );
};

export default Terms;
