export const BioGraphySvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 0.833008C8.79638 0.833008 7.60438 1.07011 6.49223 1.53078C5.38008 1.99145 4.36955 2.66666 3.51835 3.51786C1.79927 5.23695 0.833496 7.56852 0.833496 9.99967C0.833496 12.4308 1.79927 14.7624 3.51835 16.4815C4.36955 17.3327 5.38008 18.0079 6.49223 18.4686C7.60438 18.9292 8.79638 19.1663 10.0002 19.1663C12.4313 19.1663 14.7629 18.2006 16.482 16.4815C18.2011 14.7624 19.1668 12.4308 19.1668 9.99967C19.1668 8.79589 18.9297 7.60389 18.4691 6.49174C18.0084 5.37959 17.3332 4.36907 16.482 3.51786C15.6308 2.66666 14.6202 1.99145 13.5081 1.53078C12.3959 1.07011 11.2039 0.833008 10.0002 0.833008ZM10.0002 6.69051C11.4792 7.65144 13.2047 8.16398 14.9685 8.16634C15.6835 8.16634 16.371 8.08384 17.031 7.92801C17.2235 8.57884 17.3335 9.27551 17.3335 9.99967C17.3335 14.0422 14.0427 17.333 10.0002 17.333C7.25016 17.333 4.85766 15.8113 3.5835 13.5655L5.18766 11.833V10.9163C5.18766 10.6124 5.30838 10.321 5.52327 10.1061C5.73815 9.89123 6.0296 9.77051 6.3335 9.77051C6.63739 9.77051 6.92884 9.89123 7.14372 10.1061C7.35861 10.321 7.47933 10.6124 7.47933 10.9163V11.833H10.0002V6.69051ZM13.6668 9.77051C13.3629 9.77051 13.0715 9.89123 12.8566 10.1061C12.6417 10.321 12.521 10.6124 12.521 10.9163C12.521 11.2202 12.6417 11.5117 12.8566 11.7266C13.0715 11.9415 13.3629 12.0622 13.6668 12.0622C13.9707 12.0622 14.2622 11.9415 14.4771 11.7266C14.6919 11.5117 14.8127 11.2202 14.8127 10.9163C14.8127 10.6124 14.6919 10.321 14.4771 10.1061C14.2622 9.89123 13.9707 9.77051 13.6668 9.77051Z"
        fill="black"
      />
    </svg>
  );
};
