import axios from 'axios';

const instance = axios.create({ 
  baseURL: process.env.REACT_APP_API_URL
})

instance.interceptors.request.use((config) => {
  let userInfo = localStorage.getItem('persist:root-talent');
  let token = '';
  if (!!userInfo) {
    userInfo = JSON.parse(userInfo);
    token = 'Bearer ' + userInfo.jwt.replace(/"/g, '');
  }

  if (!!userInfo) {
    config.headers['Authorization'] = token.toString();
  }
  return config;
});

export default instance;
