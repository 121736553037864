import * as React from "react";
import { Modal } from "antd";
import "./CustomModal.module.scss";
import CustomButton from "../custom-button/CustomButton";

interface CustomModalProps {
  open: boolean;
  width?: string | number;
  centered?: boolean;
  footer?: React.ReactNode;
  title?: string;
  className?: string;
  children: React.ReactNode;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  confirmLoading?: boolean | undefined;
  destroy?: boolean | undefined;
  afterClose?:(e: React.MouseEvent<HTMLElement>) => void;
}

const CustomModal: React.FunctionComponent<CustomModalProps> = (props) => {
  
  return (
    <Modal
      className={props.className}
      open={props.open}
      width={props.width}
      title={props.title}
      centered={props.centered}
      footer={props.footer}
      onOk={props.onOk}
      destroyOnClose={props.destroy || false}
      onCancel={props.onCancel}
      confirmLoading={props.confirmLoading}
      maskClosable={false}
      closable={false}
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
