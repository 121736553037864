import { useEffect, lazy, Suspense, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.scss";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./app/components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Space, Spin } from "antd";
import { getCurrencies } from "app/auth/services/DexService";
import {
  addNotification,
  setCurrencyMap,
  setCurrencyPairs,
  setNotificationsCount,
} from "app/auth/slices/authSlice";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particleOptions from "./particles";
import ProductPage from "app/components/market-place-detail/Product";
import ServiceDetail from "app/components/market-place-detail/ServiceDetail";
import AllContent from "app/components/dashboard/admin-dashboard/Content/AllContent/AllContent";
import HomePage from "app/components/home-page/HomePage";
import TokenGenerator from "app/components/dashboard/admin-dashboard/TokenGenerator/TokenGenerator";
import { io, Socket } from "socket.io-client";
import Messaging from "app/components/messaging/Messaging";
import VerifyEmail from "app/components/page-not-found/VerifyEmail";
import { validateEmail } from "app/Helpers";
import TokenForm from "app/components/home-page/form/tokenesForm/TokenForm";
import TokenizationApplication from "app/components/dashboard/admin-dashboard/TokenizationApplication/TokenizationApplication";
import FindJob from "app/components/find-job/FindJob";
import MaintenancePage from "./app/components/maintenancePage/MaintenancePage";
// import FindFreelancer from "app/components/freelancer/Find-Freelancer";
const FindFreelancer = lazy(
  () => import("./app/components/freelancer/Find-Freelancer")
);
// import PersonalToken from "app/components/personalToken/PersonalToken";
const PersonalToken = lazy(
  () => import("./app/components/personalToken/PersonalToken")
);
// import PersonalTokensList from "app/components/Personal-Tokens-List/PersonalTokensList";
const PersonalTokensList = lazy(
  () => import("./app/components/Personal-Tokens-List/PersonalTokensList")
);
const ForTalent = lazy(() => import("./app/components/talent-page/TalentPage"));
const TalentToken = lazy(
  () => import("./app/components/talent-token/TalentToken")
);
const ContactsList = lazy(
  () => import("./app/components/contacts/ContactsList")
);
const AboutUs = lazy(() => import("app/components/about-us/AboutUs"));
const MarketPlace = lazy(
  () => import("app/components/market-place/MarketPlace")
);
const Privacy = lazy(() => import("app/components/privacy/Privacy"));
const Profile = lazy(() => import("app/components/profile/Profile"));
const BioGraphy = lazy(
  () => import("app/components/dashboard/biography/BioGraphy")
);
const CoinStats = lazy(
  () => import("app/components/dashboard/coin-stats/CoinSatas")
);
const Transaction = lazy(
  () => import("app/components/dashboard/transaction/Transaction")
);
const Shots = lazy(() => import("app/components/dashboard/shots/Shots"));
const Services = lazy(
  () => import("app/components/dashboard/services/Services")
);
const Goods = lazy(() => import("app/components/dashboard/goods/Goods"));
const OverView = lazy(
  () => import("app/components/dashboard/admin-dashboard/OverView/OverView")
);

const AllTalents = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/Talents/AllTalents/AllTalents"
    )
);
const AddTalents = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/Talents/AddTalents/AddTalents"
    )
);
const ContentCategories = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/Content/Categories/Categories"
    )
);
const AllOrders = lazy(
  () =>
    import("app/components/dashboard/admin-dashboard/Orders/AllOrder/AllOrder")
);
const OrderDetails = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/Orders/OrderDetails/OrderDetails"
    )
);
const ManageReviews = lazy(
  () =>
    import("app/components/dashboard/admin-dashboard/ManageReview/ManageReview")
);
const TalentChainAdmin = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/TalentChain-Admin/TalentChain-Admin"
    )
);
const KycRequests = lazy(
  () =>
    import("app/components/dashboard/admin-dashboard/KycRequests/KycRequests")
);
// const Applications = lazy(
//   () =>
//     import("app/components/dashboard/admin-dashboard/TokenizationApplication")
// );
const AllUsers = lazy(
  () => import("app/components/dashboard/admin-dashboard/AllUsers/AllUsers")
);
const Setting = lazy(
  () => import("app/components/dashboard/admin-dashboard/Setting/Setting")
);
const EditContent = lazy(
  () =>
    import(
      "app/components/dashboard/admin-dashboard/Content/AllContent/EditContent"
    )
);
const Orders = lazy(() => import("app/components/dashboard/orders/Orders"));
const UserProfile = lazy(
  () => import("app/components/user-profile/UserProfile")
);
const Connect = lazy(() => import("app/components/connect/Connect"));
const Dex = lazy(() => import("app/components/dex/Dex"));
const Disclaimer = lazy(() => import("./app/components/desclamier/Desclamier"));
const ConnectWallet = lazy(
  () => import("./app/components/connect/ConnectWallet")
);
const Terms = lazy(() => import("./app/components/terms-and-conditions/Terms"));
const NotFound = lazy(() => import("./app/components/page-not-found/NotFound"));

const Footer = lazy(() => import("./app/components/footer/Footer"));

function App() {
  const location = useLocation();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const user = useSelector((state: any) => state.auth.userProfile);
  const notifications = useSelector((state: any) => state.auth.notifications);
  const [msgCount, setMsgCount] = useState<number>(notifications);

  const dispatch = useDispatch();

  let socket: Socket;

  const setCurrencies = async () => {
    const response = await getCurrencies();
    if (response.status === 200) {
      const json = response.data;

      let pairs = [{}];
      pairs = [];
      let map: { [key: string]: {} } = {};
      json.currencies.forEach((currency: any) => {
        if (currency.code === process.env.REACT_APP_CURRENCY_CODE) {
          pairs.push({ title: "TALENT/XRP", value: currency.symbol });
        } else {
          pairs.push({
            title: `${currency.symbol}/TALENT`,
            value: currency.symbol,
          });
          map[currency.symbol] = currency;
        }
      });
      dispatch(setCurrencyMap(map));
      dispatch(setCurrencyPairs(pairs));
    }
  };

  useEffect(() => {
    setCurrencies();
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 220, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  const listenToEvents = () => {
    socket = io(`${process.env.REACT_APP_API_URL}`, {
      withCredentials: true,
      transports: ["polling"],
    });

    socket.emit("online", { uuid: user.address });

    socket.on("notification", (payload) => {
      let count = notifications;
      setMsgCount((prevState: number) => {
        count = prevState + 1;
        return count;
      });
      dispatch(addNotification(payload));
      dispatch(setNotificationsCount(count));
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      listenToEvents();
    }

    return () => {
      if (isLoggedIn && !!socket && socket.connected) {
        socket.emit("offline", { uuid: user.address });
        socket.disconnect();
      }
    };
  }, [isLoggedIn]);

  useEffect(() => {
    setMsgCount(notifications);
  }, [notifications]);

  const root = document.documentElement;
  root.style.setProperty("--color", "#415383");

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const showe = false;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "auto",
      }}
    >
      <MaintenancePage />

      {showe && (
        <Layout style={{ minHeight: "400px" }}>
          <Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Space size="middle">
                  <Spin size="large" />
                </Space>
              </div>
            }
          >
            {/* <SocketContext.Provider value={socket}> */}
            {!location.pathname.includes("dashboard") && (
              <Particles
                init={particlesInit}
                id="tsparticles"
                options={particleOptions}
              />
            )}
            {!location.pathname.includes("dashboard") && <Header />}

            <Routes>
              <Route path="/" element={<MaintenancePage />} />
              {/* <Route path="/" element={<HomePage />} /> */}
              <Route path="/TokenForm" element={<TokenForm />} />
              <Route path="/confirm/:token" element={<VerifyEmail />} />
              <Route path="/support-talent" element={<ContactsList />} />
              <Route path="/Personal-Tokens" element={<PersonalTokensList />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/personalTokens" element={<PersonalToken />} />
              <Route path="/for-talent" element={<ForTalent />} />
              <Route path="/find-job" element={<FindJob />} />
              <Route path="/find-freelancer" element={<FindFreelancer />} />
              {/* <Route path="/for-talent" element={<ForTalent />} /> */}
              <Route path="/talent-token" element={<TalentToken />} />
              <Route path="/marketplace" element={<MarketPlace />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/connect" element={<Connect />} />
              <Route path="/connect-wallet" element={<ConnectWallet />} />
              <Route path="/terms-of-services" element={<Terms />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/user-profile/:talentId" element={<UserProfile />} />
              <Route path="/service/:product" element={<ServiceDetail />} />
              <Route path="/product/:product" element={<ProductPage />} />
              <Route path="/dex/:symbol" element={<Dex />} />
              {isLoggedIn && (
                <>
                  <Route path="/messages" element={<Messaging />} />
                  <Route path="/dashboard/profile" element={<BioGraphy />} />

                  {!!user.email &&
                    !!user.fullname &&
                    user.email.length > 4 &&
                    !validateEmail(user) && (
                      <>
                        <Route
                          path="/dashboard/services"
                          element={<Services />}
                        />
                        <Route path="/dashboard/products" element={<Goods />} />
                        <Route path="/dashboard/orders" element={<Orders />} />
                      </>
                    )}
                </>
              )}
              {/* <Route path="/faq" element={<CompanyLicense />} /> */}
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {isLoggedIn && user?.role?.includes("Talent") && (
                <>
                  <Route path="/dashboard/coin-stats" element={<CoinStats />} />
                  <Route
                    path="/dashboard/transaction"
                    element={<Transaction />}
                  />
                  <Route path="/dashboard/shots" element={<Shots />} />
                </>
              )}

              {isLoggedIn && user?.role.includes("Admin") && (
                <>
                  <Route path="/dashboard/overview" element={<OverView />} />
                  <Route
                    path="/dashboard/all-talents"
                    element={<AllTalents />}
                  />
                  <Route
                    path="/dashboard/add-talent"
                    element={<AddTalents />}
                  />
                  <Route path="/dashboard/all-order" element={<AllOrders />} />
                  <Route
                    path="/dashboard/order-details"
                    element={<OrderDetails />}
                  />
                  <Route
                    path="/dashboard/all-contents"
                    element={<AllContent />}
                  />
                  <Route
                    path="/dashboard/content-categories"
                    element={<ContentCategories />}
                  />
                  <Route
                    path="/dashboard/manage-reviews"
                    element={<ManageReviews />}
                  />
                  <Route
                    path="/dashboard/talentchain-admin"
                    element={<TalentChainAdmin />}
                  />
                  <Route
                    path="/dashboard/kyc-requests"
                    element={<KycRequests />}
                  />
                  <Route
                    path="/dashboard/Applications"
                    element={<TokenizationApplication />}
                  />
                  <Route path="/dashboard/all-users" element={<AllUsers />} />
                  {
                    <Route
                      path="/dashboard/token-generator"
                      element={<TokenGenerator />}
                    />
                  }
                  {/* <Route
                    path="/dashboard/edit-content"
                    element={<EditContent />}
                  /> */}
                  <Route path="/dashboard/setting" element={<Setting />} />
                </>
              )}
              <Route path="/*" element={<NotFound />} />
            </Routes>

            {/* </SocketContext.Provider> */}
          </Suspense>
        </Layout>
      )}
      {showe && !location.pathname.includes("dashboard") && (
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Space size="middle">
                <Spin size="large" />
              </Space>
            </div>
          }
        >
          {/* <QuickLinks /> */}
          {/* <Footer /> */}
        </Suspense>
      )}
    </div>
  );
}

export default App;
