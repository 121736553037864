import React from "react";
import { Col, Row } from "antd";
import styles from "./HomePage.module.scss";

const data = [
  "Logo Design",
  "Website Design",
  "Video Editing",
  "SEO",
  "Illustration",
  "Logo Design",
  "Website Design",
  "Video Editing",
  "SEO",
  "Illustration",
];
interface TagsProps {
  text: string
}

const Marketplace : React.FunctionComponent<TagsProps> = (props) => {
  return (
    <div className={styles["benefits-section"]}>
      <div className="container">
        <h1 className={styles["benefit-title"]}>
          {props.text}
        </h1>
        <div>
          <div>
            <Row align="stretch" style={{ justifyContent: "space-between" ,gap:35}}>
              {data.map((itm, i) => (
                <Col
                  xs={24}
                  sm={11}
                  md={4}
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#192042",
                      color:"#fff",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {itm}{" "}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      {/* <style jsx>{`
      .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
      }

      @media screen and (min-width: 992px) and (max-width: 1600px) {
        .container {
          padding: 0 2rem;
        }
      }

      @media screen and (max-width: 992px) {
        .container {
          padding: 0 1rem;
        }
      }

      @media screen and (max-width: 576px) {
        .container {
          padding: 0;
        }
      }

      @media screen and (min-width: 992px) {
        .card-wrapper {
          width: calc(50% - 2.5px);
        }
      }

      @media screen and (max-width: 992px) {
        .card-wrapper {
          width: 100%;
        }
      }
    `}</style> */}
    </div>
  );
};

export default Marketplace;
