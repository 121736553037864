export const GoodsSvg = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.029 1.53961C9.32605 1.37465 9.66022 1.28809 10 1.28809C10.3398 1.28809 10.674 1.37465 10.971 1.53961L18.486 5.71361C18.6418 5.80025 18.7716 5.92697 18.862 6.08064C18.9524 6.23431 19 6.40934 19 6.58761V14.8226C18.9999 15.1793 18.9045 15.5294 18.7235 15.8367C18.5426 16.1441 18.2828 16.3975 17.971 16.5706L10.971 20.4606C10.674 20.6256 10.3398 20.7121 10 20.7121C9.66022 20.7121 9.32605 20.6256 9.029 20.4606L2.029 16.5706C1.71736 16.3975 1.45763 16.1443 1.27671 15.8372C1.0958 15.53 1.00026 15.1801 1 14.8236V6.58761C0.999993 6.40934 1.04764 6.23431 1.13802 6.08064C1.22839 5.92697 1.3582 5.80025 1.514 5.71361L9.03 1.53961H9.029Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11V21M1 6L10 11L1 6ZM10 11L19 6L10 11Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4 11.328L7 13M5.5 8.5L14.5 3.5L5.5 8.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
