export const TransactionSvg = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9583 3.66602H2.04167C1.53541 3.66602 1.125 4.07642 1.125 4.58268V19.2494C1.125 19.7556 1.53541 20.166 2.04167 20.166H13.9583C14.4646 20.166 14.875 19.7556 14.875 19.2494V4.58268C14.875 4.07642 14.4646 3.66602 13.9583 3.66602Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.3335 16.0413H8.00016M5.25016 1.83301V4.58301V1.83301ZM10.7502 1.83301V4.58301V1.83301ZM4.3335 8.70801H11.6668H4.3335ZM4.3335 12.3747H9.8335H4.3335Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
