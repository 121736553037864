import "app/components/dashboard/sidebar/SideBar.module.scss";
export const SettingSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1401 10.9404C17.1801 10.6404 17.2001 10.3304 17.2001 10.0004C17.2001 9.68039 17.1801 9.36039 17.1301 9.06039L19.1601 7.48039C19.3401 7.34039 19.3901 7.07039 19.2801 6.87039L17.3601 3.55039C17.2401 3.33039 16.9901 3.26039 16.7701 3.33039L14.3801 4.29039C13.8801 3.91039 13.3501 3.59039 12.7601 3.35039L12.4001 0.81039C12.3601 0.57039 12.1601 0.400391 11.9201 0.400391H8.08011C7.84011 0.400391 7.65011 0.57039 7.61011 0.81039L7.25011 3.35039C6.66011 3.59039 6.12011 3.92039 5.63011 4.29039L3.24011 3.33039C3.02011 3.25039 2.77011 3.33039 2.65011 3.55039L0.74011 6.87039C0.62011 7.08039 0.66011 7.34039 0.86011 7.48039L2.89011 9.06039C2.84011 9.36039 2.80011 9.69039 2.80011 10.0004C2.80011 10.3104 2.82011 10.6404 2.87011 10.9404L0.84011 12.5204C0.66011 12.6604 0.61011 12.9304 0.72011 13.1304L2.64011 16.4504C2.76011 16.6704 3.01011 16.7404 3.23011 16.6704L5.62011 15.7104C6.12011 16.0904 6.65011 16.4104 7.24011 16.6504L7.60011 19.1904C7.65011 19.4304 7.84011 19.6004 8.08011 19.6004H11.9201C12.1601 19.6004 12.3601 19.4304 12.3901 19.1904L12.7501 16.6504C13.3401 16.4104 13.8801 16.0904 14.3701 15.7104L16.7601 16.6704C16.9801 16.7504 17.2301 16.6704 17.3501 16.4504L19.2701 13.1304C19.3901 12.9104 19.3401 12.6604 19.1501 12.5204L17.1401 10.9404ZM10.0001 13.6004C8.02011 13.6004 6.40011 11.9804 6.40011 10.0004C6.40011 8.02039 8.02011 6.40039 10.0001 6.40039C11.9801 6.40039 13.6001 8.02039 13.6001 10.0004C13.6001 11.9804 11.9801 13.6004 10.0001 13.6004Z"
        fill="black"
        id="selected"
      />
    </svg>
  );
};
