import * as React from "react";
import styles from "./Edit.module.scss";
import CustomButton from "app/common/custom-button/CustomButton";
import CustomCard from "app/common/custom-card/CustomCard";
import CustomModal from "app/common/custom-modal/CustomModal";
import {
  Input,
  notification,
  Skeleton,
  Select,
  Spin,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "app/auth/slices/authSlice";
import fileUpload from "assets/images/file-upload.svg";
import Editor from "app/common/ckEditor/CkEditor";
import CustomSelect from "app/common/custom-select/CustomSelect";
import { updateProduct, updateService, uploadProduct, uploadService } from "app/auth/services/AdminService";
import axios from "axios";

interface Content {
  _id: string;
  cover: string;
  title: any;
  name: string;
  description: any;
  currency: string;
  price: string;
  type?: string;
  talent: string;
  orders: string;
  category: string[];
  contentPrice:number;
}

interface AddGoodProps {
  visible: boolean;
  setVisible: any;
  handleClose: Function;
  content: Content | undefined;
  categories: string[];
}

const EditContent: React.FunctionComponent<AddGoodProps> = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>(props?.content?.category || []);
  const [imageUrl, setImageUrl] = React.useState<string>(
    props?.content?.cover || "",
  );
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const [editorState, setEditorState] = React.useState<string>(
    props?.content?.description || "<p></p>",
  );
  const [currency, setCurrency] = React.useState<string>(
    props?.content?.currency || "TALENT",
  );
  const [title, setTitle] = React.useState<string>(props?.content?.name || "");
  const [price, setPrice] = React.useState<number>(props?.content?.contentPrice || 0);

  const [category, setCategory] = React.useState<any>({
    tags: [],
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
  });

  const onUploadClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }

  const uploadProductImage = async (file: any, id: string) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await uploadProduct(id, formData);
      if (res.status === 401) {
        notification.error({
          message: "Session expired",
          description: "Please Connect your Wallet",
        });
        dispatch(logout());
      } else if (res.status === 200 || res.status === 201) {
        notification.success({
          message: "Cover updated!",
          description: "File uploaded successfully",
        });
      } else {
        notification.error({
          message: "Operation Failed",
          description: "Please try again",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!!error?.response) {
          if (error?.response.status === 401) {
            notification.error({
              message: "Session expired!",
              description: "Please Connect your Wallet.",
            });
            dispatch(logout());
          } else {
            notification.error({
              message: "Something went wrong!",
              description: "Please try again.",
            });
          }
        } else {
          notification.error({
            message: "Something went wrong!",
            description: "Please try again.",
          });
        }
      } else {
        notification.error({
          message: "Something went wrong!",
          description: "Please try again.",
        });
      }
    } finally {
      setLoading(false);
      reset();
      props.handleClose();
    }
  }

  const uploadServiceImage = async (file: any, id: string) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await uploadService(id, formData);
      if (res.status === 401) {
        notification.error({
          message: "Session expired",
          description: "Please Connect your Wallet",
        });
        dispatch(logout());
      } else if (res.status === 200 || res.status === 201) {
        notification.success({
          message: "Cover updated!",
          description: "File uploaded successfully",
        });
      } else {
        notification.error({
          message: "Operation Failed",
          description: "Please try again",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!!error?.response) {
          if (error?.response.status === 401) {
            notification.error({
              message: "Session expired!",
              description: "Please Connect your Wallet.",
            });
            dispatch(logout());
          } else {
            notification.error({
              message: "Something went wrong!",
              description: "Please try again.",
            });
          }
        } else {
          notification.error({
            message: "Something went wrong!",
            description: "Please try again.",
          });
        }
      } else {
        notification.error({
          message: "Something went wrong!",
          description: "Please try again.",
        });
      }
    } finally {
      setLoading(false);
      reset();
      props.handleClose();
    }
  }

  const updateServiceInfo = async () => {
    setLoading(true);
    try {
      const res = await updateService({
        _id: props.content?._id || '',
        cover: imageUrl,
        title: title,
        description: editorState,
        category: tags,
        price: price,
        currency: currency
      }, props.content?._id || '');
      if (res.status === 401) {
        notification.error({
          message: "Session expired",
          description: "Please Connect your Wallet",
        });
        dispatch(logout());
      } else if (res.status === 201) {
        notification.success({
          message: "Success!",
          description: "Service updated successfully.",
        });
      } else {
        notification.error({
          message: "Operation Failed",
          description: "Something went wrong!",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!!error?.response) {
          if (error?.response.status === 401) {
            notification.error({
              message: "Session expired!",
              description: "Please Connect your Wallet.",
            });
            dispatch(logout());
          } else {
            notification.error({
              message: "Something went wrong!",
              description: "Please try again.",
            });
          }
        } else {
          notification.error({
            message: "Something went wrong!",
            description: "Please try again.",
          });
        }
      } else {
        notification.error({
          message: "Something went wrong!",
          description: "Please try again.",
        });
      }
    } finally {
      setLoading(false);
      reset();
      props.handleClose();
    }
  }

  const updateProductInfo = async () => {
    setLoading(true);
    try {
      const res = await updateProduct({
        _id: props.content?._id || '',
        cover: imageUrl,
        title: title,
        description: editorState,
        category: tags,
        price: price,
        currency: currency
      }, props.content?._id || '');
      if (res.status === 401) {
        notification.error({
          message: "Session expired",
          description: "Please Connect your Wallet",
        });
        dispatch(logout());
      } else if (res.status === 201) {
        notification.success({
          message: "Success!",
          description: "Product updated successfully.",
        });
      } else {
        notification.error({
          message: "Operation Failed",
          description: "Something went wrong!",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!!error?.response) {
          if (error?.response.status === 401) {
            notification.error({
              message: "Session expired!",
              description: "Please Connect your Wallet.",
            });
            dispatch(logout());
          } else {
            notification.error({
              message: "Something went wrong!",
              description: "Please try again.",
            });
          }
        } else {
          notification.error({
            message: "Something went wrong!",
            description: "Please try again.",
          });
        }
      } else {
        notification.error({
          message: "Something went wrong!",
          description: "Please try again.",
        });
      }
    } finally {
      setLoading(false);
      reset();
      props.handleClose();
    }
  }

  const uploadImage = (event: any) => {
    setImageUrl(URL.createObjectURL(event.target.files[0]));

    if (props.content?.type?.toLowerCase().trim() === "service") {
      uploadServiceImage(event.target.files[0], props?.content?._id || "");
    } else {
      uploadProductImage(event.target.files[0], props?.content?._id || "");
    }
  }
    
  const reset = () => {
    setTitle("");
    setEditorState("");
    setTags([]);
    setPrice(0);
    setCurrency("TALENT");
    setImageUrl("");
    setCategory((prev) => ({ tags: props?.content?.category || [] }));
  }

  const handleChange = (value) => {
    setTags(value);
  };

  const updateContent = () =>{
     if (props.content?.type?.toLowerCase().trim() === "service") {
       updateServiceInfo();
     } else {
      updateProductInfo();
     }
  }
  React.useEffect(() => {
      setTitle(props?.content?.name || "");
      setEditorState(props?.content?.description || editorState);
      setPrice(props?.content?.contentPrice || 0);
      setCurrency(props?.content?.currency || "TALENT");
      setTags(props?.content?.category || []);
      setImageUrl(props?.content?.cover || "");
      setCategory((prev) => ({ tags: props?.content?.category || [] }));
  }, [props]);

  return (
    <>
      <CustomModal
        width={660}
        className="dashboard-modal"
        onCancel={() => {
          props.setVisible(false);
        }}
        destroy={false}
        open={props.visible}
        footer={null}>
        {loading && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 4,
              display: loading ? "block" : "none",
            }}
            className={styles["flex-box"]}>
            <Spin size="large" />
          </div>
        )}
        <h1 className="modal-title">Add {props.content?.type || "Content"}</h1>

        <Popconfirm
          placement="topRight"
          title="Changes will be discarded, are you sure you want to close ?"
          onConfirm={() => {
            props.setVisible(false);
          }}
          okText="Yes"
          cancelText="No">
          <span
            style={{
              position: "absolute",
              right: "40px",
              top: "37px",
              fontSize: "30px",
              color: "black",
              cursor: "pointer",
            }}>
            ✘
          </span>
        </Popconfirm>
        <div className={styles["mb-1"]}>
          <CustomCard className="modal-card">
            <h2 className={styles["upload-shots"]}>
              {props.content?.type || "Content"} Title
            </h2>
            <Input
              className={styles["tag-input"]}
              required
              autoFocus={true}
              type="text"
              value={title}
              maxLength={80}
              placeholder="Enter title here"
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </CustomCard>
        </div>
        {/* {props.type === 'update' &&  */}
        <div className={styles["mb-1"]}>
          <CustomCard className="modal-card">
            <h2 className={styles["upload-shots"]}>
              Upload {props.content?.type || "Content"} Cover
            </h2>
            <div onClick={onUploadClick} className={styles["centered-profile"]}>
              <div className="text-center">
                <img
                  style={{
                    width: imageUrl !== "" ? "10rem" : "30px",
                    height: imageUrl !== "" ? "100%" : "auto",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src={imageUrl === "" ? fileUpload : imageUrl}
                  alt="cover"
                />
              </div>

              <input
                style={{ zIndex: "-1", visibility: "hidden" }}
                ref={uploadRef}
                onChange={uploadImage}
                type="file"
                id="upload"
                accept="image/png, image/jpg, image/jpeg"
              />
            </div>
          </CustomCard>
        </div>
        {/* } */}
        <div className={styles["mb-1"]}>
          <CustomCard className="modal-card">
            <h2 className={styles["upload-shots"]}>Description</h2>
            <Skeleton loading={false}>
              <Editor
                setEditorState={setEditorState}
                editorState={props.content?.description || editorState}
              />
            </Skeleton>
          </CustomCard>
        </div>
        <div className={styles["mb-1"]}>
          <CustomCard className="modal-card">
            <h2 className={styles["upload-shots"]}>Categories</h2>
            {/* <DynamicTags
                  state={category}
                  setState={setCategory}
                  tagTitle="Add categories"
                /> */}
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="select categories"
              defaultValue={tags}
              value={tags}
              onChange={handleChange}
              optionLabelProp="label">
              {props.categories.map((cat: string) => (
                <Option value={cat} label={cat} children={undefined}></Option>
              ))}
            </Select>
          </CustomCard>
        </div>
        <div className={styles["price-section"]}>
          <div className={styles["flex"]}>
            <div>
              <h2 className={styles["price-text"]}>Price</h2>
              <Input
                className="price-input"
                autoFocus={true}
                type="number"
                max={10000000}
                defaultValue={price}
                value={price}
                onChange={(event) => {
                  if (
                    parseFloat(event.target.value) < 0 ||
                    parseFloat(event.target.value) > 10000000
                  ) {
                    event.preventDefault();
                    setPrice(0);
                  } else {
                    setPrice(
                      parseFloat(parseFloat(event.target.value).toFixed(2)),
                    );
                  }
                }}
                required
                placeholder="Enter price here"
              />
            </div>
            <div>
              <h5 className={styles["currency-text"]}>Currency</h5>
              <CustomSelect
                className="currency-select"
                placeholder="Select currency"
                options={[]}
                defaultValue={currency}
                value={currency}
                onChange={(value) => {
                  setCurrency(value.toString());
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles["btn-div"]}>
          <CustomButton onClick={updateContent} className="primary-btn">
            Update
          </CustomButton>
        </div>
      </CustomModal>
    </>
  );
};

export default EditContent;
