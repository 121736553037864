import { removeDuplicatesForPriceData, removeDuplicatesForVolumeData } from 'app/Helpers';
import { UTCTimestamp } from 'lightweight-charts';
import Axios from './apiService';

export const getTalentBuyPrice = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/talent-buy-price`,
    params: {
      symbol: target,
    },
  });

export const getTalentSellPrice = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/talent-sell-price`,
    params: {
      symbol: target,
    },
  });

export const buyTalents = async (
  target: string,
  amount: string,
  value: string,
  limit: string = '',
) => {
  let params = {};

  if (limit === '') {
    params = {
      symbol: target,
      amount: amount,
      value: value,
    };
  } else {
    params = {
      symbol: target,
      amount: amount,
      value: value,
      limit: limit,
    };
  }

  return await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/buy-talent`,
    params: params,
  });
};

export const sellTalents = async (
  target: string,
  amount: string,
  value: string,
  limit: string = '',
) => {
  let params = {};

  if (limit === '') {
    params = {
      symbol: target,
      amount: amount,
      value: value,
    };
  } else {
    params = {
      symbol: target,
      amount: amount,
      value: value,
      limit: limit,
    };
  }

  return await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/sell-talent`,
    params: params,
  });
};

export const payment = async (
  target: string,
  amount: string,
  talentAddress: string,
) => {
  
  return await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/payment?talentAddress=${talentAddress}&symbol=${target}&amount=${amount}`,
  });
};

export const getChartData = async (
  target: string,
  period: string,
  from: string,
  to: string = '',
) =>
{
  const response = await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/chart-data`,
    params: {
      symbol: target,
      period: period,
      from: from,
      to: to,
    },
  });

  if (response.status === 200) {
    const json = response.data;

    const _priceData: {
      time: number;
      open: number;
      high: number;
      low: number;
      close: number;
    }[] = [];

    const _volumeData: {
      time: number;
      value: number;
    }[] = [];

    json.data.forEach(
      (series: {
        date: string;
        open: number;
        close: number;
        high: number;
        low: number;
        volume: number;
      }) => {
        _priceData.push({
          time: Math.floor(
            new Date(series.date).getTime() / 1000
          ) as UTCTimestamp,
          open: series.open,
          high: series.high,
          low: series.low,
          close: series.close,
        });
        //Math.floor(new Date(series.date).getTime() / 1000).toString(), //series.date.split('T')[0],
        _volumeData.push({
          time: Math.floor(
            new Date(series.date).getTime() / 1000
          ) as UTCTimestamp,
          value: series.volume,
        });
      }
    );

    return {_priceData: removeDuplicatesForPriceData(_priceData), _volumeData: removeDuplicatesForVolumeData(_volumeData)}
  } else {
    return {_priceData: [],_volumeData: []}
  }
}

export const getStats = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/market-stats`,
    params: {
      symbol: target,
    },
  });

export const getCurrencies = async () =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/user/currencies`,
  });

export const getTargetBalance = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/balance`,
    params: {
      symbol: target,
    },
  });

export const getAllBalances = async () =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/balances`,
  });

export const getOrderBook = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/order-book`,
    params: {
      symbol: target,
    },
  });

export const getOpenOffers = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/open-orders`,
    params: {
      symbol: target,
    },
  });

export const getTradeHistory = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/trade-history`,
    params: {
      symbol: target,
    },
  });

export const getExchangeHistory = async (target: string) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/exchange-history`,
    params: {
      symbol: target,
    },
  });

export const cancelOffer = async (target: string, sequence: number) =>
  await Axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/ledger/cancel-offer`,
    params: {
      symbol: target,
      sequence: sequence,
    },
  });
