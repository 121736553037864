export const TwitterSVG = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5276 0C10.4625 0 0.679688 9.78279 0.679688 21.8479C0.679688 33.913 10.4625 43.6958 22.5276 43.6958C34.5927 43.6958 44.3755 33.913 44.3755 21.8479C44.3755 9.78279 34.5927 0 22.5276 0ZM33.0273 16.4688C33.0419 16.698 33.0419 16.937 33.0419 17.1711C33.0419 24.3302 27.5897 32.5768 17.6264 32.5768C14.5541 32.5768 11.706 31.6843 9.30668 30.1481C9.74559 30.1969 10.165 30.2164 10.6137 30.2164C13.1496 30.2164 15.4807 29.3581 17.3387 27.9048C14.9588 27.8561 12.9594 26.2955 12.2766 24.1497C13.1106 24.2717 13.8616 24.2717 14.7199 24.0522C13.4945 23.8032 12.3931 23.1377 11.6027 22.1687C10.8124 21.1997 10.3819 19.987 10.3844 18.7365V18.6682C11.1013 19.073 11.945 19.3217 12.8277 19.3559C12.0857 18.8613 11.4771 18.1914 11.056 17.4053C10.6349 16.6193 10.4143 15.7415 10.4137 14.8497C10.4137 13.8403 10.6771 12.9185 11.1501 12.1188C12.5102 13.7931 14.2075 15.1625 16.1315 16.138C18.0556 17.1135 20.1634 17.6732 22.3179 17.7807C21.5522 14.0987 24.3027 11.119 27.6092 11.119C29.1697 11.119 30.5742 11.7725 31.5642 12.8259C32.7883 12.5967 33.9587 12.1383 35.0023 11.5238C34.5976 12.7771 33.749 13.8354 32.6225 14.5035C33.7149 14.3865 34.7683 14.0841 35.7436 13.6598C35.0072 14.7425 34.0855 15.7032 33.0273 16.4688Z"
        fill="#060513"
      />
    </svg>
  );
};
