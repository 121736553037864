import Axios from './apiService';

export const getAppStats = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'admin/stats'
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAllServices = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'admin/services',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getAllProducts = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'admin/products',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateService = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/update-service?id=${id}`,
      data
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateProduct = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/update-product?id=${id}`,
      data
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const removeProduct = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/remove-product?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const removeService = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/remove-service?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const uploadProduct = async (id, formData) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'admin/upload-product',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        id: id,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const uploadService = async (id, formData) => {
  let response;
  try {
    response = await Axios({
      method: 'POST',
      url: 'admin/upload-service',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
      params: {
        id: id,
      },
    });
  } catch (err) {
    return err.response;
  }
  return response;
};

export const getAllCategories = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: 'admin/categories',
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const addNewCategory = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: 'admin/category',
      data
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateCategory = async (data, id) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/update-category?id=${id}`,
      data
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

export const removeCategory = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/remove-category?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getUsersForAdmin = async () => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/users`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const generateNewCurrency = async (symbol, supply) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/generate-token?symbol=${symbol}&supply=${supply}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const removeAdmin = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/remove-admin?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const makeAdmin = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/make-admin?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const approveKyc = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/approve-kyc?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const disapproveKyc = async (id) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `admin/disapprove-kyc?id=${id}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `auth/verify-email?token=${token}`,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

export const addAdmin = async (data) => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `admin/add-admin`,
      data,
    });
    return response;
  } catch (err) {
    return err.response;
  }
};
