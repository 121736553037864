export const Web = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6159 13.5774C16.7062 12.8324 16.774 12.0874 16.774 11.3198C16.774 10.5522 16.7062 9.80722 16.6159 9.0622H20.4313C20.6119 9.78464 20.7248 10.5409 20.7248 11.3198C20.7248 12.0987 20.6119 12.855 20.4313 13.5774H16.6159ZM14.6179 19.8536C15.2952 18.6006 15.8145 17.2461 16.1757 15.8351H19.5057C18.4121 17.7182 16.677 19.1447 14.6179 19.8536ZM14.3357 13.5774H9.05292C8.94004 12.8324 8.87231 12.0874 8.87231 11.3198C8.87231 10.5522 8.94004 9.79593 9.05292 9.0622H14.3357C14.4373 9.79593 14.5164 10.5522 14.5164 11.3198C14.5164 12.0874 14.4373 12.8324 14.3357 13.5774ZM11.6943 20.3051C10.7574 18.9506 10.0011 17.4492 9.53831 15.8351H13.8504C13.3875 17.4492 12.6312 18.9506 11.6943 20.3051ZM7.1791 6.80459H3.88298C4.96534 4.91633 6.69913 3.48757 8.75943 2.78603C8.08215 4.03901 7.57418 5.39358 7.1791 6.80459ZM3.88298 15.8351H7.1791C7.57418 17.2461 8.08215 18.6006 8.75943 19.8536C6.70345 19.1443 4.97211 17.7176 3.88298 15.8351ZM2.95736 13.5774C2.77675 12.855 2.66387 12.0987 2.66387 11.3198C2.66387 10.5409 2.77675 9.78464 2.95736 9.0622H6.77273C6.68242 9.80722 6.6147 10.5522 6.6147 11.3198C6.6147 12.0874 6.68242 12.8324 6.77273 13.5774H2.95736ZM11.6943 2.32322C12.6312 3.67779 13.3875 5.19039 13.8504 6.80459H9.53831C10.0011 5.19039 10.7574 3.67779 11.6943 2.32322ZM19.5057 6.80459H16.1757C15.8224 5.40651 15.2993 4.05695 14.6179 2.78603C16.695 3.49718 18.422 4.93077 19.5057 6.80459ZM11.6943 0.0317383C5.45202 0.0317383 0.40625 5.11138 0.40625 11.3198C0.40625 14.3136 1.59553 17.1848 3.71245 19.3017C4.76065 20.3499 6.00504 21.1814 7.37457 21.7486C8.7441 22.3159 10.212 22.6079 11.6943 22.6079C14.6881 22.6079 17.5593 21.4186 19.6762 19.3017C21.7931 17.1848 22.9824 14.3136 22.9824 11.3198C22.9824 9.83745 22.6904 8.36959 22.1232 7.00006C21.5559 5.63053 20.7244 4.38614 19.6762 3.33794C18.628 2.28975 17.3836 1.45827 16.0141 0.890992C14.6446 0.323713 13.1767 0.0317383 11.6943 0.0317383Z"
        fill="#9CAFDE"
      />
    </svg>
  );
};
