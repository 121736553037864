export const LinkedInSVG = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5002 0.624023C10.4338 0.624023 0.652344 10.4055 0.652344 22.4719C0.652344 34.5383 10.4338 44.3198 22.5002 44.3198C34.5667 44.3198 44.3481 34.5383 44.3481 22.4719C44.3481 10.4055 34.5667 0.624023 22.5002 0.624023ZM17.1521 31.5274H12.7279V17.2899H17.1521V31.5274ZM14.9126 15.542C13.5153 15.542 12.6118 14.5521 12.6118 13.3277C12.6118 12.0782 13.5426 11.1178 14.9695 11.1178C16.3965 11.1178 17.2704 12.0782 17.2977 13.3277C17.2977 14.5521 16.3965 15.542 14.9126 15.542ZM33.3104 31.5274H28.8862V23.6371C28.8862 21.8006 28.2444 20.5534 26.6445 20.5534C25.4224 20.5534 24.6964 21.3977 24.3755 22.2102C24.2572 22.4992 24.2276 22.9089 24.2276 23.3163V31.5251H19.8011V21.8301C19.8011 20.0527 19.7442 18.5666 19.685 17.2876H23.5289L23.7315 19.2653H23.8202C24.4028 18.3368 25.8298 16.9667 28.2171 16.9667C31.1279 16.9667 33.3104 18.9171 33.3104 23.1092V31.5274Z"
        fill="#060513"
      />
    </svg>
  );
};
