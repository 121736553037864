import { Space } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./DashboardFooter.module.scss";

import { Footer } from "antd/lib/layout/layout";


import Icon from "@ant-design/icons";

import { TwitterOutlinedSVG } from "../../../common/icons/twitterOutlinedSvg";
import { DiscordSVG } from "../../../common/icons/discordSvg";
import { TelegramSVG } from "../../../common/icons/telegramSvg";
interface DashboardFooterProps {}

const DashboardFooter: React.FunctionComponent<DashboardFooterProps> = (
  props
) => {
  return (
    <Footer className="dashboard-footer">
      <div className={styles["footer-div"]}>
        <span className={styles["rights"]}>
          TalentChain &copy; {new Date().getFullYear()} All rights reserved
        </span>
        <Space>
          <a
            target="_blank"
            href="https://twitter.com/TalentChainXRPL"
            rel="noreferrer"
          >
            <Icon component={TwitterOutlinedSVG} />
          </a>
          <a
            target="_blank"
            href="https://t.me/TalentChainXRPL"
            rel="noreferrer"
          >
            <Icon component={TelegramSVG} />
          </a>
          <a
            target="_blank"
            href="https://discord.gg/rgaqBehq"
            rel="noreferrer"
          >
            <Icon component={DiscordSVG} />
          </a>
        </Space>
      </div>
    </Footer>
  );
};

export default DashboardFooter;
