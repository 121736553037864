import * as React from "react";
import { Button } from "antd";
import "./CustomButton.module.scss";
interface CustomButtonProps {
 className?: string;
 children: React.ReactNode;
 loading?: boolean;
 type?:
  | "link"
  | "text"
  | "ghost"
  | "default"
  | "primary"
  | "dashed"
  | undefined;
 htmlType?: "button" | "submit" | "reset" | undefined;
 onClick?: React.MouseEventHandler<HTMLButtonElement>;
 style?: React.CSSProperties;
 icon?: React.ReactNode;
 block?: boolean;
 shape?:|"round"|"circle"|"default"|undefined;
 disabled?: boolean;
}

const CustomButton: React.FunctionComponent<CustomButtonProps> = (props) => {
 return (
  <Button
   className={props.className}
   type={props.type}
   htmlType={props.htmlType}
   onClick={props.onClick}
   style={props.style}
   icon={props.icon}
   loading={props.loading}
   block={props.block}
   shape={"round"}
   disabled={props.disabled}
  >
   {props.children}
  </Button>
 );
};

export default CustomButton;
