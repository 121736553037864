import "app/components/dashboard/sidebar/SideBar.module.scss";
export const TalentsSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM13 7C13 8.6575 11.6575 10 10 10C8.3425 10 7 8.6575 7 7C7 5.3425 8.3425 4 10 4C11.6575 4 13 5.3425 13 7ZM4 14.6667C4 12.8933 7.9975 12 10 12C12.0025 12 16 12.8933 16 14.6667V16H4V14.6667Z"
        fill="black"
        id="selected"
      />
    </svg>
  );
};
