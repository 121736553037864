export const numberWithCommas = (x: number | string) => {
  if (!x) return 0;
  if (parseFloat(x.toString()) >= 1000) {
    let numbers = x.toString().split('.');
    numbers[0] = numbers[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numbers.join('.');
  } else {
    return x.toString();
  }
};

export const convertToAbsolutePath = (url: string) => {
  if (!url || url === '') return url;

  if (url.indexOf('https://') === -1 && url.indexOf('http://') === -1) {
    return `https://${url}`;
  } else {
    return url;
  }
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};


export const  onlyLettersAndNumbers = (str) => {
  return /^[A-Za-z0-9]*$/.test(str);
};

export const startWithLetter = (str) => {
  return /^[A-Za-z]*$/.test(str);
};

export const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const convertFirstLetterToUpperCase = (word: string): string => {
  if (word.length === 0) {
    return '';
  }
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
};

export const getUsdPriceFromArray = (priceArray: {symbol: string; usdPrice: string;}[], symbol: string, _price: string): string => {
  let price = '0';
  for (let i = 0; i < priceArray.length; i++) {
    if (priceArray[i].symbol === symbol) {
      price = priceArray[i].usdPrice;
      break;
    }
  }
  return parseFloat((parseFloat(price) * parseFloat(_price)).toFixed(6)).toString();
}

export const removeDuplicatesForVolumeData = (
	array: {
    time: number;
    value: number;
  }[] = []
): {
  time: number;
  value: number;
}[] => {
	let hash = Object.create(null);
  let result: {
    time: number;
    value: number;
  }[] = [];

for (let i = 0; i < array.length; i++) {
    if (!hash[array[i].time]) {
        hash[array[i].time] = true;
        result.push(array[i]);
    }
}
return result;
};

export const removeDuplicatesForPriceData = (
	array: {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
  }[] = []
): {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
}[] => {
	let hash = Object.create(null);
  let result: {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
  }[] = [];

for (let i = 0; i < array.length; i++) {
    if (!hash[array[i].time]) {
        hash[array[i].time] = true;
        result.push(array[i]);
    }
}
return result;
};

export interface Currency {
  logo: string;
  symbol: string;
  code: string;
  owner: string;
  price?: string;
  xrpPrice?: string;
  usdPrice?: string;
  createDateTime?: string;
}

export interface Talent {
  _id: string;
  fullname: string;
  address: string;
  title: string;
  tags: string[];
  skills: string[];
  role: string[];
  aboutMe: string;
  location: string;
  website: string;
  linkedin: string;
  twitter: string;
  facebook: string;
  profilePictureUrl: string;
  createdAt: string;
  currency: Currency;
  kycStatus: string;
  kycDocument: string;
  emailStatus: string;
}
export interface Product {
  _id: string;
  cover?: string;
  title: string;
  description: string;
  price: number;
  currency: string;
  category?: string[];
  talent: Talent;
}
