import * as Yup from "yup";

 const formSchema = Yup.object().shape({
  publicName: Yup.string().required("Field is required"),
  firstName: Yup.string().required("Field is required"),
  lastName: Yup.string().required("Field is required"),
  email: Yup.string().email("Invalid email").required("Field is required"),
  address: Yup.string().required("Field is required"),
  location: Yup.string().required("Field is required"),
  biography: Yup.string().required("Field is required"),
  contentTypes: Yup.array().required("is required"),
  socialPlatforms: Yup.array().required("Field is required"),
  platforms: Yup.string().required("Field is required"),
  referralSource: Yup.string().required(" required"),
  // comments: Yup.string().required("comments is required"),
  // Scouting: Yup.string().required("Scouting is required"),
  plan: Yup.string().required("Field is required"),
  distributed: Yup.string().required("Field is required"),
  benefits: Yup.string().required("Field is required"),
  physical: Yup.string().required("Field is required"),
  token: Yup.string().required("Field is required"),
});

export default formSchema;
