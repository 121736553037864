import * as React from "react";
import { Avatar, Layout, Space, Typography, Tooltip } from "antd";
import "./DashboardHeader.module.scss";
import styles from "./DashboardHeader.module.scss";
import { Link } from "react-router-dom";
import wallet from "assets/images/wallet.png";
import { useSelector } from "react-redux";
import { UserOutlined, WalletOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { LinkSvg } from "../../../common/icons/LinkSvg";

interface DashboardHeaderProps {
  title: string;
}

const DashboardHeader: React.FunctionComponent<DashboardHeaderProps> = (
  props
) => {
  const { Header } = Layout;
  const { Text } = Typography;
  const userProfile = useSelector((state: any) => state.auth.userProfile);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const address = useSelector((state: any) => state.auth.userProfile.address);

  return (
    <Header
      style={{ backgroundColor: "#FFF" }}
      className="talent-dashboard-header"
    >
      <div className={styles["header-flex"]}>
        <span className={styles["header-title"]}>{props.title}</span>
        <Space size={25}>
          {isLoggedIn ? (
            <div className="filters">
              <div className="outer-filter">
                <Space>
                  <Tooltip title="Wallet">
                    <Link to="/profile">
                      <WalletOutlined
                        style={{
                          color: "#192042",
                          fontSize: "25px",
                          margin: "3px",
                        }}
                      />
                    </Link>
                  </Tooltip>
                  <div>
                    <Text
                      style={{ width: "90px", color: "#00000" }}
                      ellipsis={true}
                    >
                      {address}
                    </Text>
                    <span className="xumm-text">XUMM</span>
                  </div>
                  <div className="inner-box">
                    <Tooltip title="Disconnect">
                      <Link to="/connect">
                        <Icon component={LinkSvg} />
                      </Link>
                    </Tooltip>
                  </div>
                  <div className="inner-box">
                    <Text
                      copyable={{ text: address }}
                      className="copyable-para"
                    ></Text>
                  </div>
                </Space>
                {/* </Link> */}
              </div>
            </div>
          ) : (
            <div className="filters">
              <div className="outer-filter">
                <Link to="/connect">
                  <Space style={{ height: "38px" }}>
                    <img src={wallet} alt="wallet" />
                    <div>
                      <h3 className={styles["connect-wallet"]}>
                        Wallet Connect
                      </h3>
                    </div>
                  </Space>
                </Link>
              </div>
            </div>
          )}
        </Space>
      </div>
    </Header>
  );
};

export default DashboardHeader;
