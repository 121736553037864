import BalloonEditor from "@ckeditor/ckeditor5-build-balloon";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./Editor.scss";

interface CkEditor {
  editorState: string;
  setEditorState: (args: string) => void;
  form?: any;
}

const Editor: React.FunctionComponent<CkEditor> = (props) => {
  return (
    <>
      <div className="rich-texteditor-ckeditor">
        <CKEditor
          editor={BalloonEditor}
          config={{
            toolbar: {
              items: [
                "fontsize",
                "|",
                "alignment",
                "|",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "bold",
                "italic",
                "|",
                "outdent",
                "indent",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "undo",
                "redo",
              ],
            },

            link: {
              addTargetToExternalLinks: true,
              defaultProtocol: "https://",
              decorators: {
                addTargetToExternalLinks: {
                  mode: "automatic",
                  callback: (url) => /^(https?:)?\/\//.test(url),
                  attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
                openInNewTab: {
                  mode: "manual",
                  label: "Open in a new tab",
                  defaultValue: true, // This option will be selected by default.
                  attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                },
              },
            },
          }}
          onReady={(editor) => {
            editor.editing.view.focus();
          }}
          placeholder="Place your text"
          data={props.editorState}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.setEditorState(data);
            if (!!props?.form) {
              props.form.setFieldsValue({
                description: data,
              });
            }
          }}
        />
      </div>
    </>
  );
};

export default Editor;
