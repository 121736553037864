import * as React from 'react';
import styles from './noData.module.scss';
interface NoDataProps {
  title: string;
  className?: number;
}

const NoData: React.FunctionComponent<NoDataProps> = (props) => {
  return (
    <div className={styles['no-data']}>
      <div className="text-center">
        <h2 className={props.className === 1 ? styles['colorWhite'] : ''}>
          {props.title}
        </h2>
      </div>
    </div>
  );
};

export default NoData;
