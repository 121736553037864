import { useState, useMemo } from "react";
import { Menu, Button } from "antd";
import Sider from "antd/lib/layout/Sider";
import "./SideBar.module.scss";
import Icon from "@ant-design/icons";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { AlignLeftOutlined } from "@ant-design/icons";
import { DashboardSvg } from "app/common/icons/DashboardSvg";
import { BioGraphySvg } from "app/common/icons/BioGraphySvg";
import { CoinsSvg } from "app/common/icons/CoinsSvg";
import { TransactionSvg } from "app/common/icons/TransactionSvg";
import { ShotsSvg } from "app/common/icons/custom-search/ShotsSvg";
import { ServicesSvg } from "app/common/icons/custom-search/ServicesSvg";
import { AdminDashboardSVG } from "app/common/icons/adminDashboard";
import { TalentsSVG } from "app/common/icons/talentsSVG";
import { AdminOrderSVG } from "app/common/icons/admin-OrderSVG";
import { TalentChainAdminSVG } from "app/common/icons/TalentChain-admin";
import { SettingSVG } from "app/common/icons/settingSVG";
import { ReviewSVG } from "app/common/icons/ReviewsSVG";
import { AdminContentSVG } from "app/common/icons/admin-contentSVG";
import { GoodsSvg } from "app/common/icons/GoodsSvg";
import { OrderSvg } from "app/common/icons/OrderSvg";
import Avatar from "antd/lib/avatar/avatar";
import CustomButton from "app/common/custom-button/CustomButton";
import talentLogo from "assets/images/TalentChain - XRPL- icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { validateEmail } from "app/Helpers";


interface SidebarProps {}
type MenuItem = Required<MenuProps>["items"][number];
const rootSubmenuKeys = ["sub2", "sub3", "sub4"];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode | AntdIconProps,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    "Overview",
    "/dashboard/overview",
    <Icon component={AdminDashboardSVG} />
  ),
  getItem("Talents", "sub2", <Icon component={TalentsSVG} />, [
    getItem("All Talents", "/dashboard/all-talents"),
    getItem("Add Talent", "/dashboard/add-talent"),
  ]),
  getItem("Content", "sub3", <Icon component={AdminContentSVG} />, [
    getItem("All Content", "/dashboard/all-contents"),
    getItem("Categories", "/dashboard/content-categories"),
  ]),
  getItem("Orders", "sub4", <Icon component={AdminOrderSVG} />, [
    getItem("All Orders", "/dashboard/all-order"),
  ]),
  getItem(
    "TalentChain Admin",
    "/dashboard/talentchain-admin",
    <Icon component={TalentChainAdminSVG} />
  ),
  getItem("All Users", "/dashboard/all-users", <Icon component={OrderSvg} />),
  getItem(
    "Token Generator",
    "/dashboard/token-generator",
    <Icon component={OrderSvg} />
  ),
  getItem(
    "KYC Requests",
    "/dashboard/kyc-requests",
    <Icon component={SettingSVG} />
  ),
  getItem(
    "Applications",
    "/dashboard/Applications",
    <AppstoreOutlined />
  ),
];

const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  const user = useSelector((state: any) => state.auth.userProfile);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];
  const path1 = location.pathname;
  const [width, setWidth] = useState(window.innerWidth);
  const [openKeys, setOpenKeys] = useState([""]);
  const [visible, setvisible] = useState({});

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const [collapsed, setCollapsed] = useState(false);
  useMemo(() => {
    width < 992 ? setCollapsed(true) : setCollapsed(false);
  }, [width]);
  useMemo(() => {
    if (
      path1 === "/dashboard/all-contents" ||
      path1 === "/dashboard/content-categories"
    ) {
      setOpenKeys(["sub3"]);
    }
    if (
      path1 === "/dashboard/all-talents" ||
      path1 === "/dashboard/add-talent"
    ) {
      setOpenKeys(["sub2"]);
    }
    if (path1 === "/dashboard/all-order") {
      setOpenKeys(["sub4"]);
    }
  }, [path1]);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
    if (collapsed == false) {
      setvisible({ display: "none" });
    } else {
      setvisible({});
    }
  };

  const menuItemsForTalent = [
    {
      key: "/dashboard/profile",
      icon: BioGraphySvg,
      title: "Profile",
      type: "menu",
    },
    {
      key: "/dashboard/coin-stats",
      icon: CoinsSvg,
      title: "Token Stats",
      type: "menu",
      children: [
        {
          key: "/dashboard/services",
          icon: ServicesSvg,
          title: "Services",
          type: "menu",
        },
        {
          key: "/dashboard/products",
          icon: GoodsSvg,
          title: "Products",
          type: "menu",
        },
      ],
    },
    {
      key: "/dashboard/transaction",
      icon: TransactionSvg,
      title: "Transactions",
      type: "menu",
    },
    {
      key: "/user-profile/:talentId",
      icon: ShotsSvg,
      title: "Public Profile",
      type: "menu",
      role: ["admin", "talent"],
    },
    {
      key: "/dashboard/services",
      icon: ServicesSvg,
      title: "Services",
      type: "menu",
      role: ["admin"],
    },
    {
      key: "/dashboard/products",
      icon: GoodsSvg,
      title: "Products",
      type: "menu",
    },
    {
      key: "/dashboard/orders",
      icon: OrderSvg,
      title: "Orders",
      type: "menu",
    },
  ];

  const menuItemsForStandardVerified =
    isLoggedIn &&
    !!user.email &&
    !!user.fullname &&
    user.email.length > 4 &&
    !validateEmail(user)
      ? [
          {
            key: "/dashboard/profile",
            icon: BioGraphySvg,
            title: "Profile",
            type: "menu",
          },
          {
            key: "/dashboard/services",
            icon: ServicesSvg,
            title: "Services",
            type: "menu",
            role: ["admin"],
          },
          {
            key: "/dashboard/products",
            icon: GoodsSvg,
            title: "Products",
            type: "menu",
          },
          {
            key: "/dashboard/orders",
            icon: OrderSvg,
            title: "Orders",
            type: "menu",
          },
        ]
      : [
          {
            key: "/dashboard/profile",
            icon: BioGraphySvg,
            title: "Profile",
            type: "menu",
          },
        ];

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(`${e.key}`);
  };
  return (
    <Sider
      width={260}
      className="dashboard-sidebar"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="logo-div">
        <CustomButton
          className="menu-btn"
          type="link"
          onClick={handleCollapsed}
        >
          {<AlignLeftOutlined />}
        </CustomButton>
        <div className="logo-dashboard" onClick={() => navigate("/")}>
          <Avatar src={talentLogo} shape="square" alt="TalentChain - XRPL- Talent Logo" />
        </div>
      </div>
      {isLoggedIn && (
        <>
          <Menu
            className="dashboard-menu"
            defaultSelectedKeys={[`/${path}`]}
            mode="inline"
            inlineCollapsed={collapsed}
          >
            {isLoggedIn && user?.role.includes("Talent")
              ? menuItemsForTalent.map((item, index) =>
                  item.type === "menu" ? (
                    <Menu.Item
                      key={index}
                      icon={<Icon component={item.icon} />}
                      onClick={() => {
                        let route = item.key;
                        if (item.title === "Public Profile") {
                          route = `/user-profile/${user._id}`;
                        }

                        navigate(`${route}`);
                      }}
                    >
                      {item.title}
                    </Menu.Item>
                  ) : item.type === "category" && !collapsed ? (
                    <span key={index} className={item.type}>
                      {item.title}
                    </span>
                  ) : null
                )
              : menuItemsForStandardVerified.map((item, index) =>
                  item.type === "menu" ? (
                    <Menu.Item
                      key={index}
                      icon={<Icon component={item.icon} />}
                      onClick={() => {
                        let route = item.key;

                        navigate(`${route}`);
                      }}
                    >
                      {item.title}
                    </Menu.Item>
                  ) : item.type === "category" && !collapsed ? (
                    <span key={index} className={item.type}>
                      {item.title}
                    </span>
                  ) : null
                )}
          </Menu>
        </>
      )}

      {isLoggedIn && user?.role.includes("Admin") && (
        <>
          {user?.role.includes("Talent") && (
            <>
              <hr />
              <h3 className="adminTitle" style={{ ...visible }}>
                Admin Dashboard
              </h3>
            </>
          )}
          <Menu
            className="dashboard-menu-admin"
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            inlineCollapsed={collapsed}
            items={items}
            onClick={onClick}
          />
        </>
      )}
    </Sider>
  );
};

export default Sidebar;
