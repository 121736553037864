import { Space, Typography } from "antd";
import React, { useState } from "react";
import Personal from "./component/Personal";
import Terms from "./component/Terms";
const { Title } = Typography;

const TokenForm = () => {
  const [screen, setScreen] = useState<number>(0);
  return (
    <Space
      style={{
        display: "flex",
        flexDirection: "column",
        width: "70%",
        margin: "10rem auto 5rem",
        color:'#fff'
      }}
    >
      
      <Space>
        {screen === 0 ? (
          <Terms setScreen={setScreen} />
        ) : screen === 1 ? (
          <Personal setScreen={setScreen} />
        ) : (
          ""
        )}
      </Space>
    </Space>
  );
};

export default TokenForm;
