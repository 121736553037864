export const CoinsSvg = () => {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 0.5C5.44433 0.5 0.833496 1.91717 0.833496 4.625V8.29167C0.833496 10.9995 5.44433 12.4167 10.0002 12.4167C14.556 12.4167 19.1668 10.9995 19.1668 8.29167V4.625C19.1668 1.91717 14.556 0.5 10.0002 0.5ZM2.66683 8.29167V7.19442C3.24739 7.5232 3.86216 7.78755 4.50016 7.98275V9.707C3.2975 9.23584 2.66683 8.67483 2.66683 8.29167ZM13.6668 8.42733V10.2341C12.8721 10.3982 11.9554 10.5183 10.9168 10.5623V8.72892C11.8394 8.69219 12.7583 8.59141 13.6668 8.42733ZM9.0835 10.5632C8.15951 10.5278 7.23987 10.418 6.3335 10.235V8.42825C7.21258 8.58775 8.14116 8.69133 9.0835 8.73075V10.5632ZM15.5002 9.707V7.98275C16.1383 7.78795 16.7531 7.52359 17.3335 7.19442V8.29167C17.3335 8.67483 16.7037 9.23584 15.5002 9.707ZM10.0002 6.91667C5.25458 6.91667 2.66683 5.40325 2.66683 4.625C2.66683 3.84675 5.25458 2.33333 10.0002 2.33333C14.7457 2.33333 17.3335 3.84675 17.3335 4.625C17.3335 5.40325 14.7457 6.91667 10.0002 6.91667Z"
        fill="black"
      />
    </svg>
  );
};
