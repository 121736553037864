export const LinkSvg = () => {
 return (
  <svg
   width='14'
   height='14'
   viewBox='0 0 14 14'
   fill='none'
   xmlns='http://www.w3.org/2000/svg'
  >
   <path
    d='M11.3834 2.61681C10.2268 1.46017 8.35508 1.46017 7.1998 2.61681L5.875 3.94161L6.57227 4.63888L7.89707 3.31407C8.63262 2.57853 9.87402 2.5006 10.6861 3.31407C11.4996 4.12755 11.4217 5.36759 10.6861 6.10314L9.36133 7.42794L10.06 8.12657L11.3848 6.80177C12.5387 5.64513 12.5387 3.77345 11.3834 2.61681ZM6.10469 10.6859C5.36914 11.4215 4.12773 11.4994 3.31563 10.6859C2.50215 9.87247 2.58008 8.63243 3.31563 7.89689L4.64043 6.57208L3.9418 5.87345L2.61699 7.19825C1.46035 8.35489 1.46035 10.2266 2.61699 11.3818C3.77363 12.5371 5.64531 12.5385 6.80059 11.3818L8.12539 10.057L7.42812 9.35978L6.10469 10.6859ZM3.55898 2.8629C3.53843 2.84255 3.51067 2.83113 3.48174 2.83113C3.45281 2.83113 3.42505 2.84255 3.40449 2.8629L2.86309 3.40431C2.84273 3.42487 2.83131 3.45263 2.83131 3.48155C2.83131 3.51048 2.84273 3.53824 2.86309 3.5588L10.4428 11.1385C10.4852 11.1809 10.5549 11.1809 10.5973 11.1385L11.1387 10.5971C11.1811 10.5547 11.1811 10.485 11.1387 10.4426L3.55898 2.8629Z'
    fill='#B0B0B0'
   />
  </svg>
 );
};
